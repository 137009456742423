var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pt-10"},[_c('h4',{staticClass:"h4"},[_vm._v("Advanced Setup")]),_vm._m(0),_c('form',{staticClass:"pt-5 space-y-5",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"space-y-5 pl-6"},[_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.formData.neg_pause_conv_threshold),expression:"formData.neg_pause_conv_threshold",modifiers:{"number":true}}],staticClass:"w-11 h-8 border border-neutral-100 rounded-md text-right pr-2",class:{ 'border-bb-error': _vm.$v.formData.neg_pause_conv_threshold.$error },attrs:{"type":"number"},domProps:{"value":(_vm.formData.neg_pause_conv_threshold)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "neg_pause_conv_threshold", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('p',[_vm._v("or more conversions last 60 days.")]),_c('new-tooltip',{staticClass:"pb-1",scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.tooltipContent.neg_pause_conv_threshold))]},proxy:true}])}),(_vm.$v.formData.neg_pause_conv_threshold.$error)?_c('div',{staticClass:"text-bb-error text-xs"},[_vm._v(" The lowest value to insert is 1. ")]):_vm._e()],1),_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_c('p',[_vm._v("more than")]),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.formData.neg_pause_click_ratio),expression:"formData.neg_pause_click_ratio",modifiers:{"number":true}}],staticClass:"w-13 h-8 border border-neutral-100 rounded-md text-right pr-2",class:{
            'border-bb-error': _vm.$v.formData.neg_pause_click_ratio.$error,
          },attrs:{"type":"number"},domProps:{"value":(_vm.formData.neg_pause_click_ratio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "neg_pause_click_ratio", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('p',[_vm._v("% of the total campaign clicks.")]),_c('new-tooltip',{staticClass:"pb-1",scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.tooltipContent.neg_pause_click_ratio))]},proxy:true}])}),(_vm.$v.formData.neg_pause_click_ratio.$error)?_c('div',{staticClass:"text-bb-error text-xs"},[_vm._v(" Insert a value between 0 and 100. ")]):_vm._e()],1)]),_c('p',{staticClass:"font-bold py-1"},[_vm._v("Max Click Reduction Impact (%):")]),_c('div',{staticClass:"space-y-5 pl-6"},[_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_vm._m(1),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.formData.neg_total_clicks_per_campaign_ratio),expression:"formData.neg_total_clicks_per_campaign_ratio",modifiers:{"number":true}}],staticClass:"w-13 h-8 border border-neutral-100 rounded-md text-right pr-2",class:{
            'border-bb-error': _vm.$v.formData.neg_total_clicks_per_campaign_ratio.$error,
          },attrs:{"type":"number"},domProps:{"value":(_vm.formData.neg_total_clicks_per_campaign_ratio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "neg_total_clicks_per_campaign_ratio", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('p',[_vm._v("% of the campaign clicks.")]),_c('new-tooltip',{staticClass:"pb-1",scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.tooltipContent.neg_total_clicks_per_campaign_ratio))]},proxy:true}])}),(_vm.$v.formData.neg_total_clicks_per_campaign_ratio.$error)?_c('div',{staticClass:"text-bb-error text-xs"},[_vm._v(" Insert a value between 0 and 100. ")]):_vm._e()],1),_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_vm._m(2),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.formData.pause_total_clicks_per_campaign_ratio),expression:"formData.pause_total_clicks_per_campaign_ratio",modifiers:{"number":true}}],staticClass:"w-13 h-8 border border-neutral-100 rounded-md text-right pr-2",class:{
            'border-bb-error': _vm.$v.formData.pause_total_clicks_per_campaign_ratio.$error,
          },attrs:{"type":"number"},domProps:{"value":(_vm.formData.pause_total_clicks_per_campaign_ratio)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "pause_total_clicks_per_campaign_ratio", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('p',[_vm._v("% of the campaign clicks.")]),_c('new-tooltip',{staticClass:"pb-1",scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(_vm._s(_vm.tooltipContent.pause_total_clicks_per_campaign_ratio))]},proxy:true}])}),(_vm.$v.formData.pause_total_clicks_per_campaign_ratio.$error)?_c('div',{staticClass:"text-bb-error text-xs"},[_vm._v(" Insert a value between 0 and 100. ")]):_vm._e()],1)]),_c('p',{staticClass:"font-bold py-1"},[_vm._v("Number of suggestions:")]),_c('div',{staticClass:"space-y-5 pl-6"},[_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_vm._m(3),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.formData.pos_suggestion_count_per_campaign),expression:"formData.pos_suggestion_count_per_campaign",modifiers:{"number":true}}],staticClass:"w-11 h-8 border border-neutral-100 rounded-md text-right pr-2",class:{
            'border-bb-error': _vm.$v.formData.pos_suggestion_count_per_campaign.$error,
          },attrs:{"type":"number"},domProps:{"value":(_vm.formData.pos_suggestion_count_per_campaign)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "pos_suggestion_count_per_campaign", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('p',[_vm._v(".")]),(_vm.$v.formData.pos_suggestion_count_per_campaign.$error)?_c('div',{staticClass:"text-bb-error text-xs"},[_vm._v(" The lowest value to insert is 5. ")]):_vm._e()]),_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_vm._m(4),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.formData.neg_suggestion_count_per_campaign),expression:"formData.neg_suggestion_count_per_campaign",modifiers:{"number":true}}],staticClass:"w-11 h-8 border border-neutral-100 rounded-md text-right pr-2",class:{
            'border-bb-error': _vm.$v.formData.neg_suggestion_count_per_campaign.$error,
          },attrs:{"type":"number"},domProps:{"value":(_vm.formData.neg_suggestion_count_per_campaign)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "neg_suggestion_count_per_campaign", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('p',[_vm._v(".")]),(_vm.$v.formData.neg_suggestion_count_per_campaign.$error)?_c('div',{staticClass:"text-bb-error text-xs"},[_vm._v(" The lowest value to insert is 5. ")]):_vm._e()]),_c('div',{staticClass:"flex flex-row gap-x-2 items-center"},[_vm._m(5),_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.formData.pause_suggestion_count_per_campaign),expression:"formData.pause_suggestion_count_per_campaign",modifiers:{"number":true}}],staticClass:"w-11 h-8 border border-neutral-100 rounded-md text-right pr-2",class:{
            'border-bb-error': _vm.$v.formData.pause_suggestion_count_per_campaign.$error,
          },attrs:{"type":"number"},domProps:{"value":(_vm.formData.pause_suggestion_count_per_campaign)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "pause_suggestion_count_per_campaign", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}}),_c('p',[_vm._v(".")]),(_vm.$v.formData.pause_suggestion_count_per_campaign.$error)?_c('div',{staticClass:"text-bb-error text-xs"},[_vm._v(" The lowest value to insert is 5. ")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"pt-5"},[_c('span',{staticClass:"font-bold py-1"},[_vm._v("Never suggest")]),_vm._v(" a single negative keyword or to pause a single keyword with: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Limit the potential impact of "),_c('span',{staticClass:"font-bold"},[_vm._v("negative")]),_vm._v(" suggestions to")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Limit the potential impact of "),_c('span',{staticClass:"font-bold"},[_vm._v("pause")]),_vm._v(" suggestions to")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Limit the number of "),_c('span',{staticClass:"font-bold"},[_vm._v("positive")]),_vm._v(" suggestions per campaign to")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Limit the number of "),_c('span',{staticClass:"font-bold"},[_vm._v("negative")]),_vm._v(" suggestions per campaign to")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v("Limit the number of "),_c('span',{staticClass:"font-bold"},[_vm._v("pause")]),_vm._v(" suggestions per campaign to")])
}]

export { render, staticRenderFns }