<template>
  <div>
    <!--MAIN CONTAINER-->
    <div class="bg-white">
      <div
        v-if="(!selectedCampaign || !selectedCampaign.value) && !showBanner"
        class="flex justify-center mt-40"
      >
        <div class="text-center space-y-2 w-120">
          <p class="text-md font-medium">Select a campaign to continue</p>
          <p class="text-sm">Select a campaign from the dropdown located in the top to get keyword suggestions.</p>
        </div>
      </div>

      <div
        v-if="!isLoading && selectedCampaign && !datedSuggestions?.upToDateSuggestions && !showBanner"
        class="mt-40"
      >
        <empty-state>
          <div>
            <p class="text-md font-medium">No suggestions found</p>
          </div>
        </empty-state>
      </div>

      <div v-if="isLoading">
        <brightbid-loader
          class="mt-40"
          size="100"
        />
      </div>
      <!-- TITLE  -->
      <div v-if="!isLoading && !pageLoading">
        <div
          v-if="selectedCampaign"
          class="flex gap-x-5 items-center justify-end my-3"
        >
          <div
            v-if="!isAllCampaignsSelected"
            class="flex flex-row items-center bg-bb-secondary-purple rounded-full gap-x-6 p-1 mr-5 mb-2"
          >
            <p class="font-medium pl-3">Autopilot is available for this campaign!</p>
            <AutoPilotButtonV2
              v-if="!isAllCampaignsSelected"
              class="h-8 bg-white"
              button-type="tertiary"
              :enabled="autopilotStatus"
              :disabled="isLoading && isAllCampaignsSelected"
              @click="toggleAutopilot"
            />
          </div>
        </div>
        <div
          :class="[
            'flex flex-row items-center mx-6 gap-x-2',
            {
              'justify-between': hasEnabledCampaigns && hasKwSuggestions && !isAllCampaignsSelected,
              'justify-end': !(hasEnabledCampaigns && hasKwSuggestions && !isAllCampaignsSelected),
            },
          ]"
        >
          <p
            v-if="hasEnabledCampaigns && hasKwSuggestions && !isAllCampaignsSelected"
            class="flex flex-row items-center gap-x-1"
          >
            Campaign Bid Strategy:
            <span class="font-bold">{{ selectedCampaign.bid_strategy_display_name }}</span> -
            {{ selectedCampaign.target_type }}
          </p>
          <div class="flex flex-row gap-2 px-6">
            <transition name="fade">
              <div
                v-if="selectedKeywords.length"
                class="flex flex-row gap-2 justify-end"
              >
                <MergeButtonRound
                  brightbid
                  :disabled="sendingRequest || selectedKeywords.some(kw => kw.suggestion_type === 'pause')"
                  :button-type="'secondary'"
                  class="w-full h-8"
                  @click="openEditPanel"
                >
                  <div class="flex gap-2 items-center">
                    <ic-edit :size="16" />
                    Edit
                  </div>
                </MergeButtonRound>
                <MergeButtonRound
                  brightbid
                  :disabled="sendingRequest"
                  :button-type="'secondary'"
                  class="w-full h-8"
                  @click="submitOriginalSuggestions('reject')"
                >
                  <div class="flex gap-2 items-center">
                    <ic-cross
                      :size="16"
                      class="text-bb-error"
                    />
                    Reject
                  </div>
                </MergeButtonRound>
                <MergeButtonRound
                  brightbid
                  :disabled="sendingRequest"
                  class="w-full h-8"
                  @click="submitOriginalSuggestions('accept')"
                >
                  <div class="flex gap-2 items-center">
                    <ic-plus :size="16" />
                    Add
                  </div>
                </MergeButtonRound>
              </div>
            </transition>
            <div
              v-if="hasEnabledCampaigns && hasKwSuggestions"
              :disabled="!canUpdateKws || autopilotStatus"
              class="cursor-pointer border text-bb-neutral-gray border-neutral-50 my-auto p-2 rounded-md hover:bg-neutral-50 duration-200 ease-linear"
              @click="getSuggestions()"
            >
              <ic-refresh
                :size="16"
                class="my-auto"
              />
            </div>
            <div
              v-if="hasEnabledCampaigns && hasKwSuggestions"
              @click="downloadCSV"
            >
              <ic-download
                :size="34"
                class="my-auto cursor-pointer hover:bg-neutral-50 rounded-md duration-200 ease-linear"
              />
            </div>
            <DropdownSelectV2
              v-if="suggestions.length"
              class="border border-neutral-50 my-auto rounded-md duration-200 ease-linear"
              hide-label
              :multiple="true"
              :selection-value="headers.filter(header => header.visible)"
              :options="toggleableHeaders"
              title=""
              @select-item="onColumnSelectionChange"
            >
              <template #icon>
                <ic-column :size="16" />
              </template>
            </DropdownSelectV2>
          </div>
        </div>
        <div
          v-if="selectedCampaign && datedSuggestions?.upToDateSuggestions"
          class="flex flex-col pt-5 gap-y-4 px-8"
        >
          <div
            v-if="autopilotStatus"
            class="flex flex-col md:flex-row w-full gap-4 p-4 bg-purple-0 rounded-md border border-bb-brand-purple shadow-bb-shadow mb-6"
          >
            <ic-stars
              size="24"
              class="text-bb-brand-purple flex-none"
            />

            <div class="flex flex-col gap-1">
              <p class="h5">Autopilot is running...</p>

              <p class="mr-4">
                You can only manage Paused keywords. You can find autopilot actions related to Positive and Negative
                keywords in the Activity History.
              </p>
            </div>

            <div class="my-auto ml-auto whitespace-no-wrap">
              <MergeButtonRound
                brightbid
                @click="$emit('go-to-activity-history')"
              >
                Check Activity History
              </MergeButtonRound>
            </div>
          </div>
        </div>
        <bb-table-v2
          v-if="suggestions.length"
          class="mx-5 border border-neutral-50 rounded-lg shadow-bb-shadow"
          :headers="visibleHeaders"
          :data="suggestions"
          :is-loading="isLoading"
          :scroll-y="true"
          :select-all="true"
          :sticky-header="true"
          :sticky-last-column="true"
          :default-sorting-order="defaultSortingOrder"
          table-height="calc(100vh - 370px)"
          :selected-items="selectedKeywords"
          @select-all="handleSelectAll"
        >
          <template #rows="{ tableData }">
            <tbody class="z-0">
              <tr
                v-for="keyword in tableData"
                :key="keyword.id"
                class="border-b border-neutral-50 cursor-pointer"
                @click.stop="shouldExpandKeyword(keyword) && expandKeyword(keyword)"
              >
                <!-- Example of rendering dynamic columns: -->
                <td
                  v-for="header in visibleHeaders"
                  :key="header.value"
                  class="px-4 py-3 min-w-35 max-w-75 whitespace-no-wrap"
                  :class="getStickyClass(header)"
                >
                  <!-- If this is the first column, add checkbox etc. -->
                  <div
                    v-if="header.value === 'searchTermView_searchTerm' || header.value === 'keyword_text'"
                    class="flex flex-row gap-2 items-center w-100"
                  >
                    <CheckBox
                      input-name="keyword-table-row"
                      :input-id="keyword.id"
                      :value="isKeywordSelected(keyword)"
                      class="h-4 pr-2 duration-200 ease-linear"
                      @input="toggleRowSelection(keyword)"
                    />
                    <div class="flex flex-col">
                      {{
                        keyword.suggestion_type === 'pause' ? keyword.keyword_text : keyword.searchTermView_searchTerm
                      }}
                      <div
                        v-if="keyword.tags?.infos?.length"
                        class="flex flex-wrap gap-x-2"
                      >
                        <div
                          v-for="(info, index) in keyword.tags.infos"
                          :key="index"
                        >
                          <span
                            class="text-xs font-bold h-6 px-2 rounded-md capitalize flex flex-row items-center justify-center gap-x-1"
                            :class="{
                              'bg-bb-decorative-cyan text-bb-solid-blue': info.toLowerCase() === 'relevant',
                              'bg-bb-light-yellow-brown text-bb-yellow-brown': info.toLowerCase() === 'irrelevant',
                              'bg-bb-pale-orange text-bb-dark-orange': info.toLowerCase() === 'best practice',
                              'bg-bb-blush-pink text-bb-solid-red':
                                info.toLowerCase() === 'poor performance (cpa)' ||
                                info.toLowerCase() === 'poor performance (cpc)',
                              'bg-bb-light-green text-bb-solid-green':
                                info.toLowerCase() === 'strong performance (cpa)' ||
                                info.toLowerCase() === 'strong performance (cpc)',
                              'bg-bb-dull-purple text-bb-violet': info.toLowerCase() === 'high impact (cost)',
                              'bg-bb-blush-pink text-bb-solid-red': ![
                                'relevant',
                                'irrelevant',
                                'poor performance',
                                'strong performance',
                                'high impact (cost)',
                              ].includes(info.toLowerCase()),
                            }"
                          >
                            {{ info }}
                            <new-tooltip
                              v-if="showTooltip(info)"
                              direction="bottom-start"
                            >
                              <ic-info :size="14" />
                              <template #content>
                                Suggested as global negative keyword applied to all campaigns
                              </template>
                            </new-tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="header.value === 'suggestion_type'"
                    class="flex flex-row justify-end items-center"
                  >
                    <div
                      class="border border-neutral-50 max-w-46 rounded-3xl px-3 py-1 cursor-pointer hover:border-bb-brand-purple duration-200 ease-linear flex flex-row justify-center"
                      @click="submitSingleKeyword(keyword, 'accept')"
                    >
                      <div class="flex items-center gap-1">
                        <component
                          :is="getIcon(keyword.suggestion_type)"
                          :size="16"
                          :class="{
                            'text-bb-warning': keyword.suggestion_type === 'pause',
                            'text-red-500': keyword.suggestion_type === 'negative',
                            'text-success': keyword.suggestion_type === 'positive',
                          }"
                        />
                        <p v-if="keyword.suggestion_type === 'pause'">Pause</p>
                        <p v-else>
                          {{ keyword.suggestion_type === 'negative' ? 'Add Negative' : 'Add Positive' }}
                        </p>
                      </div>
                    </div>
                    <VDropdown
                      :triggers="[]"
                      :shown="actionMenuOpen?.id == keyword?.id"
                      placement="bottom-end"
                    >
                      <!-- This will be the popover reference (for the events and position) -->
                      <button
                        class="mx-2 p-1 border border-neutral-50 rounded-md hover:bg-neutral-50 duration-200 ease-linear"
                        @click.stop="toggleKwActionMenu(keyword)"
                      >
                        <ic-kebab :size="18" />
                      </button>

                      <!-- This will be the content of the popover -->
                      <template #popper>
                        <div
                          v-for="action in kwActionMenu"
                          :key="action.value"
                          class="py-2 px-4 cursor-pointer hover:bg-bb-secondary-purple"
                        >
                          <div
                            class="flex items-center gap-2"
                            @click="handleAction(action.label, keyword)"
                          >
                            <component
                              :is="action.icon"
                              :size="16"
                              :class="{
                                'text-bb-disabled-buttons ': action.label === 'Edit',
                                'text-bb-error': action.label === 'Reject',
                              }"
                            />
                            <p class="font-normal text-bb-text-default">
                              {{ action.label }}
                            </p>
                          </div>
                        </div>
                      </template>
                    </VDropdown>
                  </div>
                  <div
                    v-else
                    class="truncate"
                  >
                    <div
                      v-if="header.value === 'intent'"
                      class="flex justify-center"
                    >
                      <new-tooltip direction="top-start">
                        <intent-type :intent-type="keyword.intent" />
                        <template #content
                          ><span class="capitalize">{{ keyword.intent }}</span></template
                        >
                      </new-tooltip>
                    </div>
                    <div
                      v-else-if="header.value === 'match_type'"
                      class="uppercase"
                    >
                      <span v-if="keyword.suggestion_type === 'pause'">{{ keyword.suggestion_type }}</span>
                      {{ keyword[header.value] }}
                      <span v-if="keyword.suggestion_type === 'positive' || keyword.suggestion_type === 'negative'">{{
                        keyword.suggestion_type
                      }}</span>
                    </div>
                    <div
                      v-else-if="
                        header.value === 'metrics_cost' ||
                        header.value === 'metrics_cpc' ||
                        header.value === 'metrics_cpc_campaign' ||
                        header.value === 'metrics_cpa' ||
                        header.value === 'metrics_cpa_campaign'
                      "
                    >
                      {{ formatValue(keyword[header.value]) }}
                      <span v-if="keyword[header.value] != null">
                        {{ currency }}
                      </span>
                    </div>

                    <div
                      v-else-if="
                        header.value === 'metrics_clicks' ||
                        header.value === 'metrics_impressions' ||
                        header.value === 'metrics_conversions' ||
                        header.value === 'metrics_conversionsValue'
                      "
                    >
                      {{ formatValue(keyword[header.value], 0) }}
                    </div>
                    <div
                      v-else-if="
                        header.value === 'metrics_ctr' ||
                        header.value === 'metrics_cvr' ||
                        header.value === 'metrics_roas'
                      "
                    >
                      {{ formatValue(Math.round((keyword[header.value] ?? 0) * 100)) }}
                      <span v-if="formatValue(keyword[header.value]) !== '-'">%</span>
                    </div>
                    <div v-else>
                      {{
                        keyword[header.value] !== undefined && keyword[header.value] !== null
                          ? formatValue(keyword[header.value])
                          : '-'
                      }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </bb-table-v2>
        <div>
          <transition
            name="slide"
            mode="out-in"
          >
            <div
              v-if="isEditPanelOpen"
              class="edit-keywords-panel border-l absolute z-99 bg-white right-0 top-0"
            >
              <edit-panel
                keep-alive
                :selected-keywords="selectedPositiveAndNegative"
                @close="close"
                @submit="submitUpdatedSuggestions"
              />
            </div>
          </transition>

          <transition
            name="slide"
            mode="out-in"
          >
            <div
              v-if="isMetricPanelOpen"
              class="metrics-panel border-l absolute z-20 bg-white right-0 top-0"
            >
              <metric-panel
                keep-alive
                :keyword="expandedKeyword"
                :currency="currency"
                context="keywordsuggestions"
                :buttons="[
                  {
                    label: 'Reject',
                    icon: 'ic-cross',
                    iconClass: 'text-bb-error',
                    type: 'secondary',
                    events: {
                      click: () => submitSingleKeyword(expandedKeyword),
                    },
                  },
                  {
                    label: expandedKeyword?.suggestion_type === 'negative' ? 'Add Negative' : 'Add Positive',
                    icon: 'ic-plus',
                    events: {
                      click: () => submitSingleKeyword(expandedKeyword, 'accept'),
                    },
                  },
                ]"
                @close="close"
              />
            </div>
          </transition>
        </div>
      </div>
      <div
        v-if="!pageLoading && !isLoading && showBanner"
        class="px-10 py-6 w-full container-banner"
      >
        <NoCampaignsBanner
          v-if="!hasEnabledCampaigns"
          :context="context"
          @submit="$emit('go-to-campaign-settings')"
        />
        <NoKwsBanner
          v-else-if="!hasKwSuggestions"
          :disabled="!canUpdateKws || autopilotStatus"
          :context="context"
          @submit="getSuggestions()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import Toast from '@/components/shared/Toast.vue'
import CheckBox from '@/components/input/brightbid/CheckBox.vue'
import AutopilotButton from '@/components/btn/AutopilotButton.vue'
import EditPanel from '@/views/keywords_suggestions/EditPanel.vue'
import MetricPanel from '@/views/keywords_suggestions/MetricPanel.vue'
import EmptyState from '@/components/brightbid_illustrations/EmptyState.vue'
import NoCampaignsBanner from '@/views/keywords_suggestions/NoCampaignsBanner.vue'
import NoKwsBanner from '@/views/keywords_suggestions/NoKwsBanner.vue'
import DropdownSelectV2 from '@/components/input/brightbid/DropdownSelectV2.vue'
import BbTableV2 from '@/components/table/BbTableV2'
import AutoPilotButtonV2 from '@/components/btn/AutoPilotButtonV2.vue'
import IntentType from '@/components/shared/IntentType.vue'
import ChipsCollection from '@/components/shared/ChipsCollection.vue'
import NewTooltip from '../../components/alert/NewTooltip.vue'

import IcKebab from '@/components/icon/ic-kebab'
import IcPause from '@/components/icon/brightbid/ic-pause.vue'
import IcEdit from '@/components/icon/ic-edit.vue'
import IcCross from '@/components/icon/ic-cross.vue'
import IcCheck from 'vue-material-design-icons/Check.vue'
import IcStars from '@/components/icon/brightbid/ic-stars.vue'
import IcPlus from 'vue-material-design-icons/Plus.vue'
import IcRefresh from '@/components/icon/brightbid/ic-refresh.vue'
import IcColumn from 'vue-material-design-icons/ViewColumnOutline.vue'
import IcDownload from '@/components/icon/brightbid/ic-download.vue'
import SolidBrightbidLogo from '@/components/icon/brightbid/solid-brightbid-logo.vue'
import GoogleGIcon from '@/components/icon/brightbid/google-g-icon.vue'
import IcPmax from '@/components/icon/ic-pmax.vue'
import IcInfo from 'vue-material-design-icons/InformationOutline'

const MAX_SELECTED_KEYWORDS = 15
const METRICS = [
  { label: 'Cost', value: 'metrics_cost' },
  { label: 'Clicks', value: 'metrics_clicks' },
  { label: 'CPA', value: 'metrics_cpa' },
  { label: 'CPC', value: 'metrics_cpc' },
  { label: 'Conversions', value: 'metrics_conversions' },
  { label: 'CTR', value: 'metrics_ctr' },
]

export default {
  name: 'KeywordSuggestionsView',
  components: {
    EmptyState,
    EditPanel,
    AutopilotButton,
    CheckBox,
    BrightbidLoader,
    MergeButtonRound,
    NoCampaignsBanner,
    NoKwsBanner,
    BbTableV2,
    MetricPanel,
    DropdownSelectV2,
    AutoPilotButtonV2,
    IntentType,
    ChipsCollection,
    IcEdit,
    IcCheck,
    IcCross,
    IcStars,
    IcRefresh,
    IcColumn,
    IcKebab,
    IcPlus,
    IcPause,
    IcDownload,
    SolidBrightbidLogo,
    GoogleGIcon,
    IcPmax,
    NewTooltip,
    IcInfo,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
    pageLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      selectedCampaign: null,
      currency: null,
      suggestions: [],
      selectedKeywords: [],
      modifiedSuggestions: {},
      isLoading: false,
      autopilotStatus: false,
      sendingRequest: false,
      showSortBy: false,
      isEditPanelOpen: false,
      isMetricPanelOpen: false,
      expandedKeyword: null,
      isAscending: false, // Flag to track sorting order
      sortCriteria: 'metrics_cost', // Current sorting criteria
      metrics: METRICS,
      canUpdateKws: false,
      headers: [
        {
          // conditional value depending on keyword type (pause or not)
          value: 'searchTermView_searchTerm' || 'keyword_text',
          label: 'Search Term',
          position: 'left',
          selectAll: true,
          default: true,
          visible: true,
        },
        {
          value: 'campaign_name',
          label: 'Campaign',
          position: 'left',
          visible: true,
        },
        {
          value: 'adGroup_name',
          label: 'Ad Group',
          position: 'left',
          visible: true,
        },
        {
          value: 'match_type',
          label: 'Match Type',
          position: 'left',
          visible: true,
        },
        {
          value: 'intent',
          label: 'Intent',
          position: 'center',
          visible: true,
        },
        {
          value: 'metrics_cost',
          label: 'Cost',
          position: 'left',
          visible: true,
        },
        {
          value: 'metrics_impressions',
          label: 'Impressions',
          position: 'left',
          visible: false,
        },
        {
          value: 'metrics_clicks',
          label: 'Clicks',
          position: 'left',
          visible: true,
        },
        {
          value: 'metrics_conversions',
          label: 'Conversions',
          position: 'left',
          visible: true,
        },
        {
          value: 'metrics_conversionsValue',
          label: 'Conversions Value',
          position: 'left',
          visible: false,
        },
        {
          value: 'metrics_cpc',
          label: 'CPC',
          position: 'left',
          visible: true,
        },
        {
          value: 'metrics_cpc_campaign',
          label: 'Campaign CPC',
          position: 'left',
          visible: true,
        },
        {
          value: 'metrics_cpa',
          label: 'CPA',
          position: 'left',
          visible: true,
        },
        {
          value: 'metrics_cpa_campaign',
          label: 'Campaign CPA',
          position: 'left',
          visible: true,
        },
        {
          value: 'metrics_roas',
          label: 'ROAS',
          position: 'left',
          visible: false,
        },
        {
          value: 'metrics_roas_campaign',
          label: 'Campaign ROAS',
          position: 'left',
          visible: false,
        },
        {
          value: 'metrics_ctr',
          label: 'CTR',
          position: 'left',
          visible: false,
        },
        {
          value: 'metrics_cvr',
          label: 'Conversion Rate',
          position: 'left',
          visible: false,
        },
        {
          value: 'suggestion_type',
          label: 'Action',
          position: 'right',
          default: true,
          visible: true,
        },
      ],
      defaultSortingOrder: { order: 'ASCENDING', column: 'metrics_cost' },
      kwActionMenu: [
        { label: 'Edit', value: 'edit', icon: 'IcEdit' },
        { label: 'Reject', value: 'reject', icon: 'IcCross' },
      ],
      csv: [
        { value: 'google_customer_id', label: 'Customer ID', csv: true },
        { value: 'customer_descriptiveName', label: 'Customer Name', csv: true },
        { value: 'campaign_id', label: 'Campaign ID', csv: true },
        { value: 'campaign_name', label: 'Campaign Name', csv: true },
        { value: 'adGroup_id', label: 'Ad Group ID', csv: true },
        { value: 'adGroup_name', label: 'Ad Group Name', csv: true },
        { value: 'suggestion_type', label: 'Suggestion Type', csv: true },
        { value: 'searchTermView_searchTerm', label: 'Expression', csv: true },
        { value: 'match_type', label: 'Match Type', csv: true },
        { value: 'metrics_cost', label: 'Cost', csv: true },
        { value: 'metrics_impressions', label: 'Impressions', csv: true },
        { value: 'metrics_clicks', label: 'Clicks', csv: true },
        { value: 'metrics_conversions', label: 'Conversions', csv: true },
        { value: 'metrics_conversionsValue', label: 'Conversions Value', csv: true },
        { value: 'metrics_allConversions', label: 'All Conversions', csv: true },
        { value: 'metrics_ctr', label: 'CTR', csv: true },
        { value: 'metrics_allConvRate', label: 'All Conversion Rate', csv: true },
        { value: 'metrics_cpc', label: 'CPC', csv: true },
        { value: 'metrics_cpa', label: 'CPA', csv: true },
        { value: 'metrics_cpc_campaign', label: 'Campaign CPC', csv: true },
        { value: 'metrics_cpa_campaign', label: 'Campaign CPA', csv: true },
        { value: 'data_date_range', label: 'Date Range', csv: true },
        { value: 'target_type', label: 'Optimization Target Type', csv: true },
        { value: 'intent', label: 'Inferred Intent', csv: true },
        { value: 'product', label: 'Offering', csv: true },
      ],
      actionMenuOpen: null,
    }
  },
  computed: {
    filteredActions() {
      console.log('keyword.suggestion_type:', this.keyword.suggestion_type) // Debugging the value
      return this.keyword.suggestion_type === 'pause'
        ? this.kwActionMenu.filter(action => action.label === 'Reject')
        : this.kwActionMenu
    },
    visibleHeaders() {
      return this.headers.filter(header => header.visible)
    },
    toggleableHeaders() {
      return this.headers.filter(header => !header.default)
    },
    isAllCampaignsSelected() {
      return this.selectedCampaign?.value === 'all-campaigns'
    },
    sortedSuggestions() {
      // USE FAST SORT
      if (!this.sortCriteria) {
        return this.suggestions // No sorting criteria, return original array
      }

      return this.suggestions.slice().sort((a, b) => {
        const valueA = a[this.sortCriteria]
        const valueB = b[this.sortCriteria]

        if (this.isAscending) {
          return valueA - valueB
        } else {
          return valueB - valueA
        }
      })
    },
    translateDirection() {
      return this.isAscending ? 'up' : 'down'
    },
    selectedPositiveAndNegative() {
      return this.selectedKeywords.filter(kw => kw.suggestion_type !== 'pause')
    },
    keywordsToSend() {
      return this.selectedKeywords.map(kw => {
        return {
          id: kw.id,
          type: this.getKeywordType(kw.suggestion_type),
          text:
            this.modifiedSuggestions[kw.id] ||
            (kw.suggestion_type === 'pause' ? kw.keyword_text : kw.searchTermView_searchTerm),
          match_type: kw.match_type.toUpperCase(),
          campaign_id: kw.campaign_id,
        }
      })
    },
    datedSuggestions() {
      if (!this.sortedSuggestions || this.sortedSuggestions.length === 0) return null
      const dateRanges = {}

      this.sortedSuggestions.map(item => {
        // Ensure item.data_date_range exists and is a string
        if (!item.data_date_range || typeof item.data_date_range !== 'string') {
          return item // If data_date_range is invalid, skip this iteration
        }

        const [startDate, endDate] = item.data_date_range.split(', ')

        // add start_date and end_date fields in the suggestion objects
        item.start_date = startDate
        item.end_date = endDate

        if (!dateRanges[endDate]) {
          // create a end date field in the dateRanges object
          dateRanges[endDate] = [startDate]
          return item
        }

        if (!dateRanges[endDate].includes(startDate)) {
          // if end date field already exists, add start date to the array
          dateRanges[endDate].push(startDate)
          return item
        }
      })

      // list the end dates
      const endDates = Object.keys(dateRanges)

      // get the latest end date
      const latestEndDate = endDates.reduce((latest, current) => {
        return new Date(latest) > new Date(current) ? latest : current
      })

      let latestStartDate = ''

      // get the latest start date
      if (dateRanges[latestEndDate].length > 1) {
        // get the latest start date
        latestStartDate = dateRanges[latestEndDate].reduce((latest, current) => {
          return new Date(latest) > new Date(current) ? latest : current
        })
      } else {
        latestStartDate = dateRanges[latestEndDate][0]
      }

      const START = new Date(latestStartDate)
      const END = new Date(latestEndDate)

      const upToDateSuggestions = []
      const outdatedSuggestions = []

      this.sortedSuggestions.map(item => {
        // filter the up to date and outdated suggestions
        if (START <= new Date(item.start_date) && END >= new Date(item.end_date)) {
          upToDateSuggestions.push(item)
        } else {
          outdatedSuggestions.push(item)
        }
      })

      return { upToDateSuggestions, outdatedSuggestions, START, END }
    },
    hasEnabledCampaigns() {
      return this.context?.keyword_suggestion_enabled_campaigns?.length > 0
    },
    hasKwSuggestions() {
      return this.suggestions?.length > 0
    },
    showBanner() {
      return !this.hasEnabledCampaigns || !this.hasKwSuggestions
    },
    activities() {
      return this.filteredHistory.map(item => {
        const isReverted = item.reverted_at && item.reverted_by ? true : false
        const suggestionJson = item.suggestion_json || {}

        return {
          id: item.id,
          keyword: item.keyword,
          date: item.created_at,
          matchType: item.match_type,
          addAs: item.label ? item.label.toLowerCase() : '',
          adGroup: item.ad_group_name,
          user: item?.added_by || '-',
          intent: item.intent || '-',
          actionTaken: item.action_taken ? item.action_taken : '',
          isAutoPilot: item.is_auto_pilot,
          action: isReverted ? null : 'undo',
          campaignId: item.google_campaign_id,
          adGroupId: item.ad_group_id,
          date_reverted: item.reverted_at || null,
          reverted_by: item?.reverted_by || '-',
          userPrefix: `${item?.original_action_taken === 'Accept' ? 'Added by ' : 'Rejected by '}`,
          datePrefix: `${item?.original_action_taken === 'Accept' ? 'Accepted on ' : 'Rejected on '}`,
          isReverted,

          // Metrics Data
          campaign_name: suggestionJson.campaign_name || '-',
          metrics_cost: suggestionJson.metrics_cost || '-',
          metrics_clicks: suggestionJson.metrics_clicks || '-',
          metrics_impressions: suggestionJson.metrics_impressions || '-',
          metrics_cts: suggestionJson.metrics_ctr || '-',
          metrics_cpc: suggestionJson.metrics_cpc || '-',
          metrics_cpa: suggestionJson.metrics_cpa || '-',
          metrics_cpc_campaign: suggestionJson.metrics_cpc_campaign || '-',
          metricsCpa: suggestionJson.metrics_cpa || '-',
          metrics_cpa_campaign: suggestionJson.metrics_cpa_campaign || '-',
          metrics_cvr: suggestionJson.metrics_cvr || '-',
          metrics_ctr: suggestionJson.metrics_ctr || '-',
          metrics_conversions: suggestionJson.metrics_conversions || '-',
          metrics_conversionsValue: suggestionJson.metrics_conversionsValue || '-',
          metrics_allConvRate: suggestionJson.metrics_allConvRate || '-',
          cpc_vs_campaign: suggestionJson.cpc_vs_campaign || '-',
          cpa_vs_campaign: suggestionJson.cpa_vs_campaign || '-',
          cvr_vs_campaign: suggestionJson.cvr_vs_campaign || '-',
          allConvRate_vs_campaign: suggestionJson.allConvRate_vs_campaign || '-',
          bright_perf_vs_campaign: suggestionJson.bright_perf_vs_campaign || '-',
          data_date_range: suggestionJson.data_date_range || '-',
        }
      })
    },
  },
  watch: {
    'context.selected_campaign': {
      handler: async function (newVal) {
        if (newVal?.value === null || !newVal) {
          this.selectedCampaign = newVal
          this.clearSelection()
          this.suggestions = []
          this.currency = null
          return
        }
        if (newVal.value === this.selectedCampaign?.value) return

        this.selectedCampaign = newVal
        this.isLoading = true
        this.autopilotStatus = newVal.suffix || false

        const { suggestions, currency } = await this.getKeywordSuggestions()

        this.suggestions = suggestions
        this.currency = currency

        this.clearSelection()
        this.canUpdateKws = this.checkCanUpdateKws()

        this.isLoading = false
      },
    },
    selectedKeywords: {
      handler: function (newVal) {
        if (newVal.length === 0) {
          this.isEditPanelOpen = false
        }
      },
    },
  },
  mounted() {
    // Restore column visibility from localStorage
    const savedColumns = JSON.parse(localStorage.getItem('visibleColumns') || '[]')
    if (savedColumns.length > 0) {
      this.headers.forEach(header => {
        header.visible = savedColumns.includes(header.value)
      })
    }
  },
  methods: {
    async getCustomerCampaigns(site_id) {
      try {
        const { data: campaigns } = await this.$http.get(`/search/site/${site_id}/campaigns`)

        if (campaigns.length === 0) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `This site has no eligible search campaigns that can receive keyword suggestions. Please choose a site that has eligible search campaigns.`,
              type: 'error',
            },
          })
          return []
        }

        return campaigns
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `This site does not have a connected Google Ads account. Please connect a Google Ads account to this site to receive keyword suggestions.`,
            type: 'error',
          },
        })
        return []
      }
    },
    async getKeywordSuggestions() {
      const { data: campaigns } = await this.$http.get(`/search/site/${this.context.site_id}/campaigns`)
      this.campaigns = campaigns
      const allCampaignIds = structuredClone(this.context.keyword_suggestion_enabled_campaigns).map(item => item.value)
      const campaignIds =
        this.selectedCampaign.value === 'all-campaigns' ? allCampaignIds.join(',') : this.selectedCampaign.value

      const {
        data: { currency, suggestions_negative, suggestions_pause, suggestions_positive },
      } = await this.$http.get(
        `/search/site/${this.context.site_id}/keyword-suggestions?google_campaign_ids=${campaignIds}`,
      )

      let suggestions = [
        ...suggestions_pause.map(s => ({ ...s, suggestion_type: 'pause' })),
        ...suggestions_positive.map(s => ({ ...s, suggestion_type: 'positive' })),
      ]

      if (this.selectedCampaign.value === 'all-campaigns') {
        suggestions = [...suggestions_negative.map(s => ({ ...s, suggestion_type: 'negative' })), ...suggestions]
      }

      return { suggestions, currency }
    },
    async refreshKeywords() {
      const { suggestions, currency } = await this.getKeywordSuggestions()

      this.suggestions = suggestions
      this.currency = currency
    },

    async toggleAutopilot() {
      try {
        if (this.isAllCampaignsSelected) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: 'Please select a campaign to be able to toggle Autopilot',
              type: 'error',
            },
          })
          return
        }
        this.isLoading = true
        const status = !this.autopilotStatus
        const payload = {
          user_email: this.context.user.email,
          enable_auto_pilot: status,
        }
        await axios.post(
          `/search/site/${this.context.site_id}/campaign/${this.selectedCampaign.value}/keyword-suggestions/toggle-auto-pilot`,
          payload,
        )
        if (status === false) {
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Autopilot disabled!',
              type: 'success',
            },
          })
          this.$trackGTMEvent('kw_autopilot_disabled')
          this.selectedCampaign.suffix = status
        } else {
          this.$toast.success({
            component: Toast,
            props: {
              title: 'Success',
              message: 'Autopilot enabled!',
              type: 'success',
            },
          })
          this.$trackGTMEvent('kw_autopilot_enable')
        }
        this.autopilotStatus = status
        this.isLoading = false
        this.selectedCampaign.suffix = status
      } catch (e) {
        this.error = e.response.data.error
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `${this.error}`,
            type: 'error',
          },
        })
        this.isLoading = false
      }
    },
    toggleSortBy() {
      this.showSortBy = !this.showSortBy
    },
    getKeywordType(type) {
      const typeMap = {
        pause: 'paused_kws',
        positive: 'pos_kws',
        negative: 'neg_kws',
      }

      return typeMap[type] || null
    },
    getSuggestionText(suggestion) {
      return (
        this.modifiedSuggestions[suggestion.id] ||
        (suggestion.suggestion_type === 'pause' ? suggestion.keyword_text : suggestion.searchTermView_searchTerm)
      )
    },
    updateSuggestionText(suggestion, text) {
      this.$set(this.modifiedSuggestions, suggestion.id, text)
    },
    selectKeyword(suggestion) {
      const kwIndex = this.selectedKeywords.findIndex(kw => kw.id === suggestion.id)
      if (kwIndex !== -1) {
        this.selectedKeywords.splice(kwIndex, 1)
        return
      }

      if (this.selectedKeywords.length === MAX_SELECTED_KEYWORDS) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Editing limit reached',
            message: `Only ${MAX_SELECTED_KEYWORDS} suggestions can be edited at a time. Accepting or rejecting is still available.`,
            type: 'success',
          },
        })
        return
      }

      this.selectedKeywords.push(suggestion)
    },
    getIcon(suggestionType) {
      switch (suggestionType) {
        case 'pause':
          return 'ic-pause'
        case 'negative':
        case 'positive':
          return 'ic-plus'
        default:
          return ''
      }
    },
    async submitUpdatedSuggestions(suggestions) {
      // get the selected paused suggestions
      const selectedPausedSuggestions = this.keywordsToSend
        .filter(kw => kw.type === 'paused_kws')
        .map(keyword => {
          return {
            id: keyword.id,
            type: keyword.type,
            original_type: keyword.type,
            text: keyword.text,
            match_type: keyword.match_type.toUpperCase(),
            campaign_id: keyword.campaign_id,
          }
        })

      // get the new suggestions
      const newSuggestions = suggestions.map(suggestion => {
        return {
          id: suggestion.id,
          original_type: this.getKeywordType(suggestion.original_type),
          type: this.getKeywordType(suggestion.suggestion_type),
          text: suggestion.text,
          match_type: suggestion.match_type.toUpperCase(),
          campaign_id: suggestion.campaign_id,
        }
      })

      const sortedNewSuggestions = this.sortSuggestionByCampaign(newSuggestions)
      const sortedPausedSuggestions = this.sortSuggestionByCampaign(selectedPausedSuggestions)

      sortedNewSuggestions.forEach(campaign => {
        this.acceptSuggestions(campaign.suggestions, campaign.campaign_id, true)
      })
      sortedPausedSuggestions.forEach(campaign => {
        this.acceptSuggestions(campaign.suggestions, campaign.campaign_id, false)
      })
    },
    async submitOriginalSuggestions(action) {
      const suggestions = this.keywordsToSend.map(keyword => {
        return {
          id: keyword.id,
          original_type: keyword.type,
          type: keyword.type,
          text: keyword.text,
          match_type: keyword.match_type.toUpperCase(),
          campaign_id: keyword.campaign_id,
        }
      })

      const sortedSuggestions = this.sortSuggestionByCampaign(suggestions)
      sortedSuggestions.forEach(campaign => {
        if (action === 'accept') {
          this.acceptSuggestions(campaign.suggestions, campaign.campaign_id, false)
        }
        if (action === 'reject') {
          this.rejectSuggestions(campaign.suggestions, campaign.campaign_id)
        }
      })
    },
    sortSuggestionByCampaign(suggestions) {
      const result = []
      suggestions.forEach(suggestion => {
        let campaign = result.find(c => c.campaign_id === suggestion.campaign_id)
        if (!campaign) {
          campaign = {
            campaign_id: suggestion.campaign_id,
            suggestions: [],
          }
          result.push(campaign)
        }
        campaign.suggestions.push(suggestion)
      })
      return result
    },
    async acceptSuggestions(suggestions, campaign_id, isEdited) {
      this.sendingRequest = true
      this.$emit('set-page-loading', true, 'KEYWORD_SUGGESTIONS')
      const acceptURL = isEdited
        ? 'keyword-suggestions/edit-accept-bulk'
        : `campaign/${campaign_id}/keyword-suggestions/accept-bulk`
      this.$trackGTMEvent('kw_suggestion_accept', {
        suggestions: suggestions.length,
        interaction_type: isEdited ? 'edit' : 'default',
      })
      try {
        await axios.post(`/search/site/${this.context.site_id}/${acceptURL}`, {
          user_email: this.context.user.email,
          kws: suggestions,
        })

        this.sendingRequest = false
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'The search term was successfully added',
            type: 'success',
          },
        })
        this.isMetricPanelOpen = false
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to add the keyword suggestions',
            type: 'error',
          },
        })
        this.sendingRequest = false
      }

      this.clearSelection()
      await this.refreshKeywords()
      this.$emit('refetch-activity-history')
      this.$emit('set-page-loading', false, 'KEYWORD_SUGGESTIONS')
    },
    async rejectSuggestions(suggestions, campaign_id) {
      this.sendingRequest = true
      this.$emit('set-page-loading', true, 'KEYWORD_SUGGESTIONS')
      this.$trackGTMEvent('kw_suggestion_reject', {
        suggestions: suggestions.length,
      })
      try {
        await axios.post(
          `/search/site/${this.context.site_id}/campaign/${campaign_id}/keyword-suggestions/reject-bulk`,
          {
            user_email: this.context.user.email,
            kws: suggestions,
          },
        )

        this.sendingRequest = false
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'The search term was successfully rejected',
            type: 'success',
          },
        })
        this.isMetricPanelOpen = false
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to reject the keyword suggestions',
            type: 'error',
          },
        })
        this.sendingRequest = false
      }
      this.clearSelection()
      await this.refreshKeywords()
      this.$emit('refetch-activity-history')
      this.$emit('set-page-loading', false, 'KEYWORD_SUGGESTIONS')
    },
    openEditPanel() {
      if (this.selectedKeywords.length === 0) return
      if (this.selectedKeywords.length > MAX_SELECTED_KEYWORDS) {
        this.$toast.warning({
          component: Toast,
          props: {
            title: 'Selection Limit Reached',
            message: `You can only edit ${MAX_SELECTED_KEYWORDS} keywords at a time.`,
            type: 'warning',
          },
        })
      } else {
        this.$trackGTMEvent('kw_suggestion_edit', {
          suggestions: this.selectedKeywords.length,
        })
        this.isMetricPanelOpen = false
        this.isEditPanelOpen = true
      }
    },
    clearSelection() {
      if (this.selectedKeywords.length === 0) return
      this.selectedKeywords = []
      this.isEditPanelOpen = false
    },
    close() {
      this.isEditPanelOpen = false
      this.isMetricPanelOpen = false
    },
    sortBy(criteria) {
      this.showSortBy = false
      this.sortCriteria = criteria
    },
    getLabelByValue(metric_value) {
      return this.metrics.find(metric => metric.value === metric_value)?.label
    },
    toggleDirection() {
      this.isAscending = !this.isAscending
    },
    async getSuggestions() {
      this.isLoading = true
      try {
        await this.$http.post(`/search/site/${this.context.site_id}/generate-keyword-suggestions`, {
          use_task: true,
        })

        this.$toast.success({
          component: Toast,
          props: {
            title: 'Great, new suggestions will be generated!',
            message: 'This may take a few minutes. Feel free to leave the page and return later.',
            type: 'success',
          },
        })
        this.storeKwsStoredData()
        this.canUpdateKws = false
      } catch (e) {
        this.removeKwsStoredData()
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to get new keyword suggestions',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    retrieveKwsStoredData() {
      const data = localStorage.getItem('keywordSuggestions')
      return data ? JSON.parse(data) : {}
    },
    storeKwsStoredData() {
      let data = this.retrieveKwsStoredData()
      data[this.context.site_id] = {
        timeGenerated: new Date().getTime(),
      }
      localStorage.setItem('keywordSuggestions', JSON.stringify(data))
    },
    removeKwsStoredData() {
      let data = this.retrieveKwsStoredData()
      delete data[this.context.site_id]
      localStorage.setItem('keywordSuggestions', JSON.stringify(data))
    },
    checkCanUpdateKws() {
      // check if the time elapsed is less than 2 minutes from previous generation
      const storedData = this.retrieveKwsStoredData()
      const lastTimeKwsGeneration = storedData[this.context.site_id]
        ? storedData[this.context.site_id]?.timeGenerated
        : 0
      const timeElapsed = new Date().getTime() - parseInt(lastTimeKwsGeneration)
      return timeElapsed > 120000
    },
    handleSelectAll(checked) {
      if (checked) {
        this.selectedKeywords = [...this.suggestions]
      } else {
        this.selectedKeywords = []
      }
    },
    toggleRowSelection(keyword) {
      const index = this.selectedKeywords.findIndex(kw => kw.id === keyword.id)
      if (index === -1) {
        if (this.selectedKeywords.length < MAX_SELECTED_KEYWORDS) {
          this.selectedKeywords.push(keyword)
        } else {
          this.$toast.warning({
            component: Toast,
            props: {
              title: 'Selection Limit Reached',
              message: `You can only select up to ${MAX_SELECTED_KEYWORDS} keywords.`,
              type: 'warning',
            },
          })
        }
      } else {
        this.selectedKeywords.splice(index, 1)
      }
    },
    isKeywordSelected(keyword) {
      return this.selectedKeywords.some(kw => kw.id === keyword.id)
    },
    expandKeyword(keyword) {
      if (this.expandedKeyword === keyword) {
        this.expandedKeyword = null
        this.isMetricPanelOpen = false
      } else {
        this.expandedKeyword = keyword
        this.isMetricPanelOpen = true
      }
    },

    submitSingleKeyword(kw, action) {
      const kws = [
        {
          id: kw.id,
          type: this.getKeywordType(kw.suggestion_type),
          text:
            this.modifiedSuggestions[kw.id] ||
            (kw.suggestion_type === 'pause' ? kw.keyword_text : kw.searchTermView_searchTerm),
          match_type: kw.match_type.toUpperCase(),
          campaign_id: kw.campaign_id,
        },
      ]
      if (action === 'accept') {
        this.acceptSuggestions(kws, kw.campaign_id, false)
      } else {
        this.rejectSuggestions(kws, kw.campaign_id)
      }
    },
    toggleColumnVisibility(header) {
      header.visible = !header.visible
    },

    // This is the new method to handle multi-select changes from DropdownSelect
    onColumnSelectionChange(selectedItems) {
      // Reset visibility based on selected items
      this.headers.forEach(header => {
        header.visible = !!selectedItems.find(item => item.value === header.value)
      })

      // Save the selected columns to localStorage
      const visibleColumns = this.headers.filter(header => header.visible).map(header => header.value)
      localStorage.setItem('visibleColumns', JSON.stringify(visibleColumns))
    },
    formatValue(value, decimals = 1) {
      if (value === null || value === undefined || value === 'N/A') {
        return '-'
      }
      if (typeof value === 'number') {
        // Treat numbers as zero if they are below a small threshold (e.g., 0.01)
        const threshold = 0.01
        if (Math.abs(value) < threshold) {
          return '-'
        }
        // Handle integer numbers
        if (value % 1 === 0) {
          return value.toString()
        }
        // Handle general floating-point numbers
        const formattedValue = value.toFixed(decimals) // Use the decimals parameter
        if (formattedValue.endsWith('.0')) {
          return parseInt(formattedValue).toString() // Remove decimal if it ends with .0
        }
        return formattedValue
      }

      return value
    },
    getStickyClass(header) {
      if (header.position === 'left' && header.value === 'searchTermView_searchTerm') {
        return 'sticky left-0 bg-white z-10 border-r border-neutral-50 truncate'
      } else if (header.position === 'right' && header.value === 'suggestion_type') {
        return 'sticky right-0 bg-white z-10 border-l border-neutral-50'
      }
      return ''
    },
    toggleKwActionMenu(keyword) {
      this.actionMenuOpen = keyword
    },
    handleAction(action, keyword) {
      if (action === 'Edit') {
        this.toggleRowSelection(keyword)
        this.openEditPanel()
      } else if (action === 'Reject') {
        this.submitSingleKeyword(keyword)
      }
      this.actionMenuOpen = null
    },
    downloadCSV() {
      // Create the headers for the CSV from `this.headers`
      const visibleHeaders = this.csv.filter(csv => csv.csv)
      const csv = visibleHeaders.map(csv => csv.label)

      // Create the rows for the CSV based on `this.suggestions`
      const rows = this.suggestions.map(item => visibleHeaders.map(csv => item[csv.value] || ''))

      // Create the CSV content
      let csvContent = 'data:text/csv;charset=utf-8,'
      csvContent += csv.join(',') + '\n' // Add the headers
      rows.forEach(row => {
        csvContent += row.join(',') + '\n' // Add the data rows
      })

      // Create a link to trigger the download
      const encodedUri = encodeURI(csvContent)
      const link = document.createElement('a')
      link.setAttribute('href', encodedUri)
      link.setAttribute('download', 'keyword_suggestions.csv') // The filename of the CSV
      document.body.appendChild(link)
      link.click() // Trigger the download
      document.body.removeChild(link) // Clean up the DOM
    },
    showTooltip(info) {
      return info === 'best practice'
    },
    shouldExpandKeyword(keyword) {
      return !keyword.tags?.infos?.includes('best practice')
    },
  },
}
</script>

<style scoped>
.main-container {
  overflow-y: auto;
  height: calc(100vh - 211px);
}

.btn-autopilot {
  background: linear-gradient(93deg, #84fae4 -45.04%, #6366fa 67.26%);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* Panel slides in from the right */
.slide-enter-active,
.slide-leave-active {
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-out;
}

.slide-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.max-h-52 {
  max-height: 13rem;
}

.edit-keywords-panel {
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  width: 400px;
  min-width: 300px;
  z-index: 20;
  border-left: 1px solid #e5e7eb;
  overflow-y: auto;
}
.metrics-panel {
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  width: 400px;
  min-width: 300px;
  z-index: 20;
  border-left: 1px solid #e5e7eb;
  overflow-y: auto;
}
</style>
