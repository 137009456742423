<template>
  <page>
    <page-section
      full-width
      bottom-border
      class="relative"
      :no-bottom-padding="keywordSuggestionsState.matches(STATES.CAMPAIGN_SETTINGS)"
    >
      <div :class="['flex justify-between', { 'pb-10': isKeywordOrActivityView }]">
        <page-title>
          <div class="flex flex-row items-center gap-4 font-medium">
            <div
              v-if="!keywordSuggestionsState.matches(STATES.KEYWORD_SUGGESTIONS)"
              class="border p-2 rounded-md cursor-pointer"
              @click="backToKeywordSuggestions"
            >
              <ic-arrow class="h-3 w-3" />
            </div>
            <div class="flex flex-col">
              <h2 class="h2">{{ PageTitles[keywordSuggestionsState.value] }}</h2>
            </div>
          </div>
          <div v-if="keywordSuggestionsState.matches(STATES.CAMPAIGN_SETTINGS)">
            <page-tabs
              :tabs="pageTabs"
              :selected-tab="selectedTab"
              class="border-b-2 border-neutral-50 text-sm pt-6"
            >
              <div
                v-for="tab in pageTabs"
                :key="tab.value"
                class="cursor-pointer z-10 duration-300 ease-linear"
                :class="{
                  'border-bb-brand-purple text-bb-brand-purple': selectedTab === tab.value,
                  'text-bb-text-default border-neutral-50 hover:border-neutral-100': selectedTab !== tab.value,
                }"
                style="border-bottom-width: 2px; margin-bottom: -2px"
              >
                <div
                  class="px-6 flex gap-2 items-center pb-2"
                  @click="selectTab(tab.value)"
                >
                  <p>
                    {{ tab.label }}
                  </p>
                </div>
              </div>
            </page-tabs>
          </div>
        </page-title>

        <div
          v-if="keywordSuggestionsState.matches(STATES.KEYWORD_SUGGESTIONS)"
          class="flex items-center text-sm"
          :class="isPageLoading ? 'text-bb-disabled-gray cursor-not-allowed' : 'cursor-pointer'"
        >
          <!--SEARCH/SELECT CAMPAIGN-->
          <div v-if="!keywordSuggestionsState.matches(STATES.CAMPAIGN_SETTINGS)">
            <search-input
              ref="selectCampaignField"
              class="w-500 pl-5"
              input-id="search-campaign"
              input-name="search-campaign"
              placeholder="Search Campaign..."
              :options="campaignOptions"
              options-suffix="autopilot"
              @select-item="selectCampaign"
            />
          </div>
          <div
            class="flex items-center px-2"
            @click="sendEvent(EVENTS.GO_TO_ACTIVITY_HISTORY)"
          >
            <MergeButtonRound
              button-type="secondary"
              class="border-none"
            >
              <template #left-icon>
                <ic-history :size="16" />
              </template>
              Activity History</MergeButtonRound
            >
          </div>
          <div
            class="flex items-center px-2"
            @click="sendEvent(EVENTS.GO_TO_CAMPAIGN_SETTINGS)"
          >
            <MergeButtonRound
              button-type="secondary"
              class="border-none"
            >
              <template #left-icon>
                <ic-cog :size="16" />
              </template>
              Settings</MergeButtonRound
            >
          </div>
        </div>
      </div>
    </page-section>
    <div
      ref="main_container"
      class="main-container base-scrollbar relative bg-white"
      :class="{ 'stop-scrolling': isPageLoading }"
    >
      <div
        v-if="isPageLoading && (loadingPage === keywordSuggestionsState.value || loadingPage === null)"
        class="absolute inset-0 bg-white z-50 bg-opacity-75 transition-opacity"
      >
        <div class="h-full flex items-center justify-center">
          <brightbid-loader size="120" />
        </div>
      </div>

      <transition name="tab-transition-left">
        <keep-alive>
          <keyword-suggestions-v2
            v-if="keywordSuggestionsState.matches(STATES.KEYWORD_SUGGESTIONS)"
            :context="keywordSuggestionsState.context"
            :page-loading="isPageLoading"
            :selected-campaign="keywordSuggestionsState.context.selected_campaign"
            @go-to-activity-history="sendEvent(EVENTS.GO_TO_ACTIVITY_HISTORY)"
            @go-to-campaign-settings="sendEvent(EVENTS.GO_TO_CAMPAIGN_SETTINGS)"
            @refetch-activity-history="refetchActivityHistory(keywordSuggestionsState.context.site_id)"
            @set-page-loading="setPageLoading"
          />
        </keep-alive>
      </transition>
      <transition name="tab-transition-right">
        <keep-alive>
          <activity-history
            v-if="keywordSuggestionsState.matches(STATES.ACTIVITY_HISTORY)"
            :context="keywordSuggestionsState.context"
            @refetch-activity-history="refetchActivityHistory(keywordSuggestionsState.context.site_id)"
            @set-page-loading="setPageLoading"
          />
        </keep-alive>
      </transition>
      <transition name="tab-transition-right">
        <keep-alive>
          <campaign-settings
            v-if="keywordSuggestionsState.matches(STATES.CAMPAIGN_SETTINGS)"
            :context="keywordSuggestionsState.context"
            :selected-tab="selectedTab"
            @refetch-campaigns="refetchCampaigns"
            @set-page-loading="setPageLoading"
            @add-expression="addExpression"
            @remove-expression="removeExpression"
            @update-expression="updateExpression"
            @update-business-description-and-extra-info-state="updateBusinessDescriptionAndExtraInfoState"
          />
        </keep-alive>
      </transition>
    </div>
  </page>
</template>

<script>
import { interpret, State } from 'xstate'
import KeywordSuggestionsMachine, { EVENTS, STATES } from '@/core/machines/keyword-suggestions'

import Page from '@/components/base/page/Page.vue'
import PageSection from '@/components/base/page/PageSection.vue'
import BrightbidLoader from '@/components/loader/BrightbidLoader.vue'
import SearchInput from '@/components/input/brightbid/SearchInput.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import PageTabs from '@/components/shared/PageTabs.vue'

import KeywordSuggestionsV2 from '@/views/keywords_suggestions/KeywordSuggestionsV2.vue'
import ActivityHistory from '@/views/keywords_suggestions/ActivityHistory.vue'
import CampaignSettings from '@/views/keywords_suggestions/Settings/index.vue'

import IcCog from '@/components/icon/brightbid/ic-cog.vue'
import IcHistory from '@/components/icon/brightbid/ic-history.vue'
import IcArrow from '@/components/icon/brightbid/ic-arrow.vue'

import Toast from '@/components/shared/Toast.vue'
import { mapState } from 'vuex'

const stateDefinition = KeywordSuggestionsMachine.initialState

const startingState = State.create(stateDefinition)

const ALL_CAMPAIGNS = {
  label: 'All Campaigns',
  value: 'all-campaigns',
  suffix: '',
}

const PageTitles = {
  KEYWORD_SUGGESTIONS: 'Optimization',
  ACTIVITY_HISTORY: 'Activity History',
  CAMPAIGN_SETTINGS: 'Settings',
}

const GTM_EVENT_MAPPING = {
  GO_TO_CAMPAIGN_SETTINGS: 'kw_suggestion_settings',
  GO_TO_ACTIVITY_HISTORY: 'kw_suggestion_activity_history',
}

export default {
  name: 'KeywordsSuggestionsMainPage',
  components: {
    BrightbidLoader,
    KeywordSuggestionsV2,
    ActivityHistory,
    MergeButtonRound,
    CampaignSettings,
    PageTabs,
    Page,
    PageSection,
    IcCog,
    IcHistory,
    IcArrow,
    SearchInput,
  },
  data() {
    return {
      keywordSuggestionsService: interpret(KeywordSuggestionsMachine).start(startingState),
      keywordSuggestionsState: KeywordSuggestionsMachine.initialState,
      STATES,
      EVENTS,
      PageTitles,

      isPageLoading: false,
      loadingPage: null,
      pageTabs: [
        { label: 'Keywords', value: 'keywords' },
        { label: 'Feed the AI', value: 'feedAI' },
      ],
      selectedTab: 'keywords',
    }
  },
  computed: {
    ...mapState('site', ['selectedSite']),
    ...mapState('auth', ['user']),
    campaignOptions() {
      const campaigns = structuredClone(this.keywordSuggestionsState.context).campaigns.map(campaign => ({
        label: campaign.name,
        value: campaign.campaign_id,
        suffix: campaign.autopilot,
        keyword_suggestion: campaign.keyword_suggestion,
        target_type: campaign.target_type,
        bid_strategy_display_name: campaign.bid_strategy_display_name,
      }))

      const enabledCampaigns = campaigns.filter(campaign => campaign.keyword_suggestion)

      if (this.keywordSuggestionsState.matches(STATES.KEYWORD_SUGGESTIONS)) {
        return enabledCampaigns.length === 0 ? [] : this.addAllCampaignsOption(enabledCampaigns)
      }

      if (this.keywordSuggestionsState.matches(STATES.ACTIVITY_HISTORY)) {
        return campaigns.length === 0 ? [] : this.addAllCampaignsOption(campaigns)
      }

      return []
    },
    isKeywordOrActivityView() {
      return (
        this.keywordSuggestionsState.matches(STATES.KEYWORD_SUGGESTIONS) ||
        this.keywordSuggestionsState.matches(STATES.ACTIVITY_HISTORY)
      )
    },
  },
  watch: {
    async selectedSite(newSite) {
      if (newSite.value !== this.siteId) {
        this.initializeKeywordSuggestions()
      }
    },
    campaignOptions(newVal) {
      const { selected_campaign } = this.keywordSuggestionsState.context
      if (newVal.length === 0) return
      if (selected_campaign !== ALL_CAMPAIGNS && selected_campaign !== null && selected_campaign) return
      this.selectCampaign(newVal[0])
      this.updateSearchInputData(newVal[0])
    },
  },
  async created() {
    if (!this.selectedSite) return
    await this.initializeKeywordSuggestions()
  },
  methods: {
    async initializeKeywordSuggestions() {
      this.isPageLoading = true

      const site_id = this.selectedSite.value
      const [campaigns, activity_history, keyword_exclusions, offerings, top_keywords, site_details] =
        await Promise.all([
          this.getCustomerCampaigns(site_id),
          this.getActivityHistory(site_id),
          this.getKeywordSuggestionExclusions(site_id),
          this.getSiteOfferings(site_id),
          this.getTopKeywords(site_id),
          this.getSiteDetails(site_id),
        ])

      const keyword_suggestion_enabled_campaigns = campaigns
        .filter(campaign => campaign.keyword_suggestion)
        .map(campaign => ({
          label: campaign.name,
          value: campaign.campaign_id,
          suffix: campaign.autopilot,
        }))

      const startingContext = {
        user: this.user,
        organization_id: this.selectedSite.site_organization_id,
        business_description: site_details.description,
        extra_info: site_details.extra_info,
        offerings,
        top_keywords,
        site_id,
        campaigns,
        activity_history,
        keyword_suggestion_enabled_campaigns,
        keyword_exclusions,
      }
      this.isPageLoading = false
      if (this.$route.query?.state) {
        startingState.value = this.$route.query.state
      }
      this.keywordSuggestionsService = interpret(KeywordSuggestionsMachine)
        .onTransition(state => {
          this.keywordSuggestionsState = state
        })
        .start(State.from(startingState.value, startingContext))
    },

    // SET STATE
    sendEvent(event) {
      this.keywordSuggestionsService.send(event)
      if (GTM_EVENT_MAPPING[event]) {
        this.$trackGTMEvent(GTM_EVENT_MAPPING[event])
      }
      // take string after 'GO_TO_' and set it as the new state
      if (typeof event === 'string' && event.includes('GO_TO_')) {
        const newState = event.split('GO_TO_')[1]
        this.$router.push({ query: { ...this.$route.query, state: newState } })
      } else {
        this.$router.push({ query: { ...this.$route.query, state: undefined } })
      }
    },
    setPageLoading(isLoading, page) {
      this.$refs.main_container.scrollTop = 0
      this.isPageLoading = isLoading
      this.loadingPage = page
    },
    addAllCampaignsOption(campaigns) {
      const campaignCount = campaigns.length

      // If there's only one campaign, show ALL_CAMPAIGN
      if (campaignCount === 1) {
        return [ALL_CAMPAIGNS].concat(campaigns)
      }
      return [ALL_CAMPAIGNS].concat(campaigns)
    },
    backToKeywordSuggestions() {
      if (this.isPageLoading || this.loadingPage) return
      this.sendEvent(EVENTS.BACK)

      const { keyword_suggestion_enabled_campaigns } = this.keywordSuggestionsState.context
      const { selected_campaign } = this.keywordSuggestionsState.context
      const enabledCampaignValues = keyword_suggestion_enabled_campaigns.map(item => item.value)

      if (enabledCampaignValues.length === 0) {
        this.selectCampaign(null)
        return
      }
      if (!enabledCampaignValues.includes(selected_campaign?.value) && selected_campaign?.value !== 'all-campaigns') {
        const numEnabledCampaigns = enabledCampaignValues.length
        const defaultSelected = numEnabledCampaigns === 1 ? enabledCampaignValues[0] : null

        // If there is only one campaign, set it as default selected
        // If there are multiple campaigns, set 'All Campaigns' as default selected
        const campaignToSelect = numEnabledCampaigns > 1 ? ALL_CAMPAIGNS : defaultSelected
        this.selectCampaign(campaignToSelect)
        return
      }

      // keep the selected campaign
      this.selectCampaign(selected_campaign)
    },
    selectTab(tabValue) {
      this.selectedTab = tabValue
    },

    // FETCH DATA
    async getSiteOfferings(site_id) {
      try {
        const { data: offerings } = await this.$http.get(`/common/site/${site_id}/offering`)
        return offerings
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `Failed to load site offerings. Please try again later.`,
            type: 'error',
          },
        })

        return []
      }
    },
    async getTopKeywords(site_id) {
      try {
        const { data: topKeywords } = await this.$http.get(
          `/search/site/${site_id}/keyword-suggestions/top_n_search_terms/${5}`,
        )
        return topKeywords
      } catch (e) {
        console.error(e)
      }
    },
    async getSiteDetails(site_id) {
      try {
        const { data: siteDetails } = await this.$http.get(`/common/site/${site_id}`)
        return siteDetails
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `Failed to load site details. Please try again later.`,
            type: 'error',
          },
        })
      }
    },
    async getCustomerCampaigns(site_id) {
      try {
        const { data: campaigns } = await this.$http.get(`/search/site/${site_id}/campaigns`)

        if (campaigns.length === 0) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `This site has no eligible search campaigns that can receive keyword suggestions. Please choose a site that has eligible search campaigns.`,
              type: 'error',
            },
          })
          return []
        }

        return campaigns
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `This site does not have a connected Google Ads account. Please connect a Google Ads account to this site to receive keyword suggestions.`,
            type: 'error',
          },
        })
        return []
      }
    },
    async getActivityHistory(site_id) {
      try {
        const { data: history } = await this.$http.get(`/search/site/${site_id}/keyword-suggestions/action-history`)
        return history
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `Failed to load activity history. Please try again later.`,
            type: 'error',
          },
        })
        return []
      }
    },
    async getKeywordSuggestionExclusions(site_id) {
      try {
        const { data: keywordExclusions } = await this.$http.get(
          `/search/site/${site_id}/keyword-suggestions/kws_exclusions`,
        )
        return keywordExclusions
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: `Failed to load keyword suggestion exclusions. Please try again later.`,
            type: 'error',
          },
        })
      }
    },
    addExpression(expressionData) {
      try {
        this.sendEvent({ type: EVENTS.ADD_EXPRESSION, payload: { expression: expressionData } })
      } catch (e) {
        if (e.response) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `Failed to add expression. Please try again later.`,
              type: 'error',
            },
          })
        }
      }
    },
    removeExpression(id) {
      try {
        this.sendEvent({ type: EVENTS.REMOVE_EXPRESSION, payload: { expression_id: id } })
      } catch (e) {
        if (e.response) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `Failed to remove expression. Please try again later.`,
              type: 'error',
            },
          })
        }
      }
    },
    updateExpression(expressionData) {
      try {
        this.sendEvent({ type: EVENTS.UPDATE_EXPRESSION, payload: { expression: expressionData } })
      } catch (e) {
        if (e.response) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `Failed to update expression. Please try again later.`,
              type: 'error',
            },
          })
        }
      }
    },
    updateBusinessDescriptionAndExtraInfoState(payload) {
      try {
        this.sendEvent({ type: EVENTS.UPDATE_BUSINESS_DESCRIPTION_AND_EXTRA_INFO, payload })
      } catch (e) {
        if (e.response) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `Failed to update business description and extra info state. Please try again later.`,
              type: 'error',
            },
          })
        }
      }
    },

    async refetchActivityHistory(site_id) {
      try {
        this.isPageLoading = true
        this.loadingPage = 'ACTIVITY_HISTORY'
        const history = await this.getActivityHistory(site_id)
        this.sendEvent({ type: EVENTS.UPDATE, payload: { activity_history: history } })
      } catch (e) {
        if (e.response) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `Failed to load activity history. Please try again later.`,
              type: 'error',
            },
          })
        }
      } finally {
        this.isPageLoading = false
        this.loadingPage = null
      }
    },
    async refetchCampaigns() {
      try {
        this.isPageLoading = true
        this.loadingPage = 'CAMPAIGN_SETTINGS'
        const campaigns = await this.getCustomerCampaigns(this.selectedSite.value)
        const keyword_suggestion_enabled_campaigns = campaigns
          .filter(campaign => campaign.keyword_suggestion)
          .map(campaign => ({
            label: campaign.name,
            value: campaign.campaign_id,
            suffix: campaign.autopilot,
          }))

        this.sendEvent({ type: EVENTS.UPDATE, payload: { campaigns, keyword_suggestion_enabled_campaigns } })
      } catch (e) {
        if (e.response) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `Failed to load campaigns. Please try again later.`,
              type: 'error',
            },
          })
        }
      } finally {
        this.isPageLoading = false
        this.loadingPage = null
      }
    },

    // SELECT CAMPAIGN
    async selectCampaign(campaign) {
      const { selected_campaign } = this.keywordSuggestionsState.context

      if (campaign?.value === null) {
        this.updateSearchInputData(ALL_CAMPAIGNS)
        return
      }

      if (campaign?.value === selected_campaign?.value) {
        this.updateSearchInputData(campaign)
        return
      }
      this.sendEvent({ type: EVENTS.SELECT_CAMPAIGN, payload: { selectedCampaign: campaign } })
    },
    updateSearchInputData(campaign) {
      this.$nextTick(() => {
        const selectCampaignField = this.$refs.selectCampaignField
        if (selectCampaignField) {
          // Update the search input with the campaign label
          selectCampaignField.searchInput = campaign.label

          // Update the selectedItem to reflect the campaign
          selectCampaignField.selectedItem = campaign
        } else {
          console.warn('selectCampaignField is undefined. Make sure the component is rendered.')
        }
      })
    },
  },
}
</script>
<style scoped lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 220px);
}

.stop-scrolling {
  overflow-y: hidden;
}
</style>
