<template>
  <div
    class="rounded-full w-fit"
    :class="chipTagStyling.background"
  >
    <p
      class="capitalize text-xs min-w-20 py-0.5 font-bold flex justify-center"
      :class="chipTagStyling.text"
    >
      {{ label }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ChipTag',
  props: {
    label: {
      type: String,
      required: true,
    },
  },
  computed: {
    chipTagStyling() {
      switch (this.label) {
        case 'Accepted':
          return { text: 'text-bb-green-accepted', background: 'bg-bb-green-50' }
        case 'Rejected':
          return { text: 'text-bb-error', background: 'bg-bb-red-50' }
        case 'Reverted':
          return { text: 'text-bb-warning-200', background: 'bg-bb-warning-100' }
        case 'Paused':
          return { text: 'text-bb-violet', background: 'bg-bb-dull-purple' }
        default:
          return { text: 'text-bb-text-secondary', background: 'bg-neutral-50' }
      }
    },
  },
}
</script>

<style scoped></style>
