<template>
  <div>
    <div class="mt-4 mb-10">
      <div
        v-if="filteredHistory.length"
        class="mx-6 space-y-3"
      >
        <div class="flex justify-end h-8 gap-2 px-6">
          <div class="flex flex-row gap-2">
            <transition name="fade">
              <div
                v-if="selectedActivities.length"
                class="flex flex-row gap-2 justify-end"
              >
                <!--
                <MergeButtonRound
                  button-type="secondary"
                  class="min-w-45 h-8"
                  @click="openConfirmationModal(UNDO_TYPES.UNDO_AND_REJECT)"
                >
                  <div class="flex gap-2 items-center">
                    <ic-cross
                      :size="16"
                      class="text-bb-error"
                    />
                    Undo & Reject
                  </div>
                </MergeButtonRound>-->
                <MergeButtonRound
                  button-type="primary"
                  class="w-full h-8"
                  @click="openConfirmationModal(UNDO_TYPES.UNDO)"
                >
                  <div class="flex gap-2 items-center">
                    <ic-curved-arrow size="16" />
                    {{ selectedActivities.length < 2 ? 'Undo' : 'Undo All' }}
                  </div>
                </MergeButtonRound>
              </div>
            </transition>
          </div>
        </div>
        <!-- METRICS TABLE COMPONENT -->
        <bb-table-v2
          v-if="campaignActivityHistory.length"
          class="border border-neutral-50 rounded-lg shadow-bb-shadow"
          :headers="headers"
          :data="filteredHistory"
          :is-loading="false"
          :default-sorting-order="defaultSortingOrder"
          :scroll-y="true"
          :sticky-header="true"
          :open-pagination-below="false"
          table-height="calc(100vh - 370px)"
          :selected-items="selectedActivityIds"
          @select-all="handleSelectAll"
        >
          <template #rows="{ tableData }">
            <tbody class="z-0">
              <tr
                v-for="keyword in tableData"
                :key="keyword.id"
                class="border-b border-neutral-50 cursor-pointer"
              >
                <!-- Example of rendering dynamic columns: -->
                <td
                  v-for="header in headers"
                  :key="header.value"
                  class="px-4 py-3 min-w-32 max-w-75 whitespace-no-wrap"
                  :class="getStickyClass(header)"
                  @click.stop="expandKeyword(keyword)"
                >
                  <div v-if="header.value === 'keyword'">
                    <!-- If this is the first column, add checkbox etc. -->
                    <div
                      v-if="header.value === 'keyword'"
                      class="flex flex-row gap-2 items-center w-100"
                    >
                      <check-box
                        :value="isKeywordSelected(keyword)"
                        :disabled="keyword.action_taken === 'Reverted'"
                        :input-id="keyword.id"
                        input-name="keyword-table-row"
                        class="h-4 mr-2 duration-200 ease-linear"
                        @input="toggleRowSelection(keyword)"
                      />
                      <div class="flex flex-col">
                        {{ keyword.keyword }}

                        <div
                          v-if="keyword.suggestion_json?.tags?.infos?.length"
                          class="flex flex-row gap-1 flex-wrap"
                        >
                          <span
                            v-for="info in keyword.suggestion_json.tags.infos"
                            :key="info"
                            class="text-xs font-bold h-6 px-2 rounded-md capitalize flex flex-row items-center"
                            :class="{
                              'bg-bb-decorative-cyan text-bb-solid-blue': info.toLowerCase() === 'relevant',
                              'bg-bb-light-yellow-brown text-bb-yellow-brown': info.toLowerCase() === 'irrelevant',
                              'bg-bb-pale-orange text-bb-dark-orange': info.toLowerCase() === 'best practice',
                              'bg-bb-blush-pink text-bb-solid-red':
                                info.toLowerCase() === 'poor performance (cpa)' ||
                                info.toLowerCase() === 'poor performance (cpc)',
                              'bg-bb-light-green text-bb-solid-green':
                                info.toLowerCase() === 'strong performance (cpa)' ||
                                info.toLowerCase() === 'strong performance (cpc)',
                              'bg-bb-dull-purple text-bb-violet': info.toLowerCase() === 'high impact (cost)',
                              'bg-bb-blush-pink text-bb-solid-red': ![
                                'relevant',
                                'irrelevant',
                                'poor performance',
                                'strong performance',
                                'high impact (cost)',
                              ].includes(info.toLowerCase()),
                            }"
                          >
                            {{ info }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else-if="header.value === 'created_at'"
                    class="flex flex-col"
                  >
                    <p>
                      {{ actionTaken(keyword) }}
                      {{ keyword.created_at }}
                    </p>
                    <p v-if="!keyword.reverted_at < 1">Reverted on {{ keyword.reverted_at }}</p>
                  </div>
                  <div
                    v-else-if="header.value === 'label'"
                    class="flex flex-col"
                  >
                    <status :status="keyword.label.toLowerCase()" />
                  </div>
                  <div v-else-if="header.value === 'added_by'">
                    <p><span v-if="!keyword.reverted_by < 1">Added by</span> {{ keyword.added_by }}</p>
                    <p v-if="!keyword.reverted_by < 1">Reverted by {{ keyword.reverted_by }}</p>
                  </div>
                  <div v-else-if="header.value === 'action_taken'">
                    <chip-tag
                      v-if="keyword.action_taken === 'Accept'"
                      :label="'Accepted'"
                    />
                    <chip-tag
                      v-else-if="
                        keyword.action_taken === 'Rejected' ||
                        keyword.action_taken === 'Reverted' ||
                        keyword.action_taken === 'Paused'
                      "
                      :label="keyword.action_taken"
                    />
                  </div>
                  <div
                    v-else-if="header.value === 'action_button'"
                    class="flex flex-row justify-end items-center"
                  >
                    <div
                      :class="
                        keyword.action_taken !== 'Reverted'
                          ? 'hover:border-bb-brand-purple cursor-pointer'
                          : 'border-neutral-50 cursor-not-allowed text-bb-disabled-buttons'
                      "
                      class="border max-w-46 rounded-3xl px-3 py-1 duration-200 ease-linear"
                      @click="keyword.action_taken !== 'Reverted' && handleClick(UNDO_TYPES.UNDO, keyword)"
                    >
                      <div class="flex items-center gap-1">
                        <ic-curved-arrow
                          :size="16"
                          :class="
                            keyword.action_taken !== 'Reverted' ? 'text-bb-brand-purple' : 'text-bb-disabled-buttons'
                          "
                        />
                        <p>Undo</p>
                      </div>
                    </div>
                    <!-- This will be used once there is an endpoint for undo and reject
                    <VDropdown
                      :triggers="[]"
                      :shown="actionMenuOpen?.id == keyword?.id"
                      placement="bottom-end"
                    >
                      <button
                        class="mx-2 p-1 border border-neutral-50 rounded-md hover:bg-neutral-50 duration-200 ease-linear"
                        @click.stop="toggleKwActionMenu(keyword)"
                      >
                        <ic-kebab :size="18" />
                      </button>

                      <template #popper>
                        <div
                          v-for="action in kwActionMenu"
                          :key="action.value"
                          class="py-2 px-4 cursor-pointer hover:bg-bb-secondary-purple"
                        >
                          <div
                            class="flex items-center gap-2"
                            @click="handleAction('Undo & Reject', keyword)"
                          >
                            <ic-cross
                              :size="16"
                              class="text-bb-error"
                            />
                            <p class="font-normal text-bb-text-default">
                              {{ action.label }}
                            </p>
                          </div>
                        </div>
                      </template>
                    </VDropdown>
                    -->
                  </div>
                  <div
                    v-else
                    class="truncate"
                  >
                    <div>
                      {{
                        keyword[header.value] !== undefined && keyword[header.value] !== null
                          ? formatValue(keyword[header.value])
                          : '-'
                      }}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </bb-table-v2>
      </div>
      <div
        v-else
        class="mt-10"
      >
        <empty-state>
          <div>
            <p class="text-md font-medium">No activity history found</p>
          </div>
        </empty-state>
      </div>
    </div>

    <!--MODAL-->
    <bb-base-modal
      v-if="modalConfig"
      :width="modalConfig.size.width"
      :height="modalConfig.size.height"
      @close="closeModal"
    >
      <template #container>
        <confirm-undo-modal-form
          v-if="modalConfig.modal === 'confirm-undo-activity'"
          :undo-type="modalConfig.undoType"
          @confirm-undo-activity="confirmUndoActivity"
          @close-modal="closeModal"
        />
      </template>
    </bb-base-modal>
    <transition
      name="slide"
      mode="out-in"
    >
      <div
        v-if="isMetricPanelOpen"
        class="metrics-panel border-l absolute z-20 bg-white right-0 top-0"
      >
        <metric-panel
          keep-alive
          :keyword="expandedKeyword"
          :currency="currency"
          context="keywordsuggestions"
          :buttons="[
            ...(expandedKeyword.action_taken !== 'Reverted'
              ? [
                  {
                    label: 'Undo',
                    icon: 'ic-curved-arrow',
                    type: 'primary',
                    events: {
                      click: () => handleClick(UNDO_TYPES.UNDO, expandedKeyword),
                    },
                  },
                ]
              : []),
          ]"
          @close="closeModal"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Fuse from 'fuse.js'

import { mapState } from 'vuex'
import { UNDO_TYPES } from '@/utils/enums'

import EmptyState from '@/components/brightbid_illustrations/EmptyState'
import bbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import ConfirmUndoModalForm from '@/components/modals/forms/ConfirmUndoModalForm.vue'
import Toast from '@/components/shared/Toast.vue'
import Status from '@/components/shared/Status.vue'
import CheckBox from '@/components/input/brightbid/CheckBox.vue'
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import BbTableV2 from '@/components/table/BbTableV2.vue'
import ChipTag from '@/components/shared/ChipTag'
import MetricPanel from '@/views/keywords_suggestions/MetricPanel.vue'

import IcCurvedArrow from '@/components/icon/brightbid/ic-curved-arrow.vue'

const MAX_SELECTED_KEYWORDS = 15
const ConfirmUndoModalSize = { width: '560px', height: '322px' }
const ConfirmUndoModal = 'confirm-undo-activity'

export default {
  name: 'ActivityHistory',
  components: {
    CheckBox,
    ConfirmUndoModalForm,
    bbBaseModal,
    EmptyState,
    Status,
    IcCurvedArrow,
    MergeButtonRound,
    BbTableV2,
    ChipTag,
    MetricPanel,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      UNDO_TYPES,
      headers: [
        { label: 'Suggestion', value: 'keyword', position: 'left', visible: true },
        { label: 'Date', value: 'created_at', position: 'left', visible: true },
        { label: 'Added As', value: 'label', position: 'left', visible: true },
        { label: 'Ad Group', value: 'ad_group_name', position: 'left', visible: true },
        { label: 'Match Type', value: 'match_type', position: 'left', visible: true },
        { label: 'User', value: 'added_by', position: 'left', visible: true },
        { label: 'Action Taken', value: 'action_taken', position: 'left', visible: true },
        { label: 'Action', value: 'action_button', position: 'right', visible: true },
      ],
      kwActionMenu: [{ label: 'Undo & Reject', value: 'edit', icon: 'IcCross' }],
      selectedActivities: [],
      selectedKeyword: {},
      defaultSortingOrder: { order: 'DESCENDING', column: 'created_at' && 'Action' },
      activeFilter: 'All',
      isMetricPanelOpen: false,
      currency: null,
      selectedCampaign: null,
      modalConfig: null,
      actionMenuOpen: null,
      expandedKeyword: null,
      query: '',
    }
  },
  computed: {
    activities() {
      return this.filteredHistory.map(item => {
        const isReverted = !!(item.reverted_at && item.reverted_by)
        const suggestionJson = item.suggestion_json || {}

        return {
          ...item, // Keep all the original properties of the item
          isReverted, // Add the `isReverted` flag
          campaign_name: suggestionJson.campaign_name || '-',
          metrics_cost: suggestionJson.metrics_cost || '-',
          metrics_clicks: suggestionJson.metrics_clicks || '-',
          metrics_impressions: suggestionJson.metrics_impressions || '-',
          metrics_ctr: suggestionJson.metrics_ctr || '-',
          metrics_cpc: suggestionJson.metrics_cpc || '-',
          metrics_cpa: suggestionJson.metrics_cpa || '-',
          metrics_cpc_campaign: suggestionJson.metrics_cpc_campaign || '-',
          metrics_cpa_campaign: suggestionJson.metrics_cpa_campaign || '-',
          metrics_cvr: suggestionJson.metrics_cvr || '-',
          metrics_conversions: suggestionJson.metrics_conversions || '-',
          metrics_conversionsValue: suggestionJson.metrics_conversionsValue || '-',
          metrics_allConvRate: suggestionJson.metrics_allConvRate || '-',
          cpc_vs_campaign: suggestionJson.cpc_vs_campaign || '-',
          cpa_vs_campaign: suggestionJson.cpa_vs_campaign || '-',
          cvr_vs_campaign: suggestionJson.cvr_vs_campaign || '-',
          allConvRate_vs_campaign: suggestionJson.allConvRate_vs_campaign || '-',
          bright_perf_vs_campaign: suggestionJson.bright_perf_vs_campaign || '-',
          data_date_range: suggestionJson.data_date_range || '-',
        }
      })
    },
    ...mapState('auth', ['user']),
    selectedActivityIds() {
      return this.selectedActivities.map(activity => activity.id)
    },
    campaignActivityHistory() {
      if (!this.selectedCampaign) return this.context.activity_history
      return this.context.activity_history.filter(activity => {
        if (this.selectedCampaign.value === activity.google_campaign_id) {
          return activity
        }
      })
    },
    filteredHistory() {
      if (!this.query) {
        return this.campaignActivityHistory
      }
      const rules = {
        shouldSort: true,
        isCaseSensitive: false,
        keys: ['keyword'],
      }
      const fuse = new Fuse(this.campaignActivityHistory, rules)
      return fuse.search(this.query).map(result => result.item)
    },
    historySummaryCount() {
      const activityTypeCount = {
        positive: 0,
        negative: 0,
        pause: 0,
        accept: 0,
        rejected: 0,
        reverted: 0,
      }
      this.campaignActivityHistory.forEach(activity => {
        switch (activity.action_taken.toLowerCase()) {
          case 'accept':
            activityTypeCount.accept++
            if (activity.label) {
              const labelKey = activity.label.toLowerCase()
              activityTypeCount[labelKey] = (activityTypeCount[labelKey] || 0) + 1
            }
            break
          case 'rejected':
            activityTypeCount.rejected++
            break
          case 'reverted':
            activityTypeCount.reverted++
            break
        }
      })
      return activityTypeCount
    },
    nonRevertedActivities() {
      return this.activities.filter(activity => activity.actionTaken !== 'reverted')
    },
    tableData() {
      return this.filteredHistory || []
    },
    actionTaken() {
      return function (keyword) {
        const checkAction = action => keyword.original_action_taken === action || keyword.action_taken === action

        switch (true) {
          case checkAction('Accept'):
            return 'Accepted on'
          case checkAction('Rejected'):
            return 'Rejected on'
          case checkAction('Paused'):
            return 'Paused on'
          default:
            return 'Reverted on'
        }
      }
    },
  },
  watch: {
    'context.selected_campaign': {
      handler: function (newVal) {
        if (newVal?.value === null) return
        if (newVal?.value === this.selectedCampaign) return
        if (newVal?.value === 'all-campaigns') {
          this.selectedCampaign = null
          return
        }
        this.selectedCampaign = newVal
        this.selectedActivities = []
      },
    },
  },
  mounted() {
    this.selectedActivities = []
    if (this.context.selected_campaign?.value === 'all-campaigns') {
      this.selectedCampaign = null
      return
    }
    this.selectedCampaign = this.context.selected_campaign
  },
  methods: {
    toggleRowSelection(keyword) {
      const index = this.selectedActivities.findIndex(activity => activity.id === keyword.id)
      if (index === -1) {
        if (this.selectedActivities.length < MAX_SELECTED_KEYWORDS) {
          this.selectedActivities.push(keyword)
        } else {
          this.$toast.warning({
            component: Toast,
            props: {
              title: 'Selection Limit Reached',
              message: `You can only select up to ${MAX_SELECTED_KEYWORDS} keywords.`,
              type: 'warning',
            },
          })
        }
      } else {
        this.selectedActivities.splice(index, 1)
      }
    },
    isKeywordSelected(keyword) {
      return this.selectedActivities.some(activity => activity.id === keyword.id)
    },
    formatValue(value) {
      // Generic formatting function, if needed
      if (typeof value === 'number') return value.toFixed(2)
      return value
    },
    getStickyClass(header) {
      if (header.position === 'left' && header.value === 'keyword') {
        return 'sticky left-0 bg-white z-10 border-r border-neutral-50 truncate'
      } else if (header.position === 'right' && header.value === 'action_button') {
        return 'sticky right-0 bg-white z-10 border-l border-neutral-50'
      }
      return ''
    },
    selectionToggle(type) {
      if (type === 'select-all') {
        this.selectedActivities = this.nonRevertedActivities
      }
      if (type === 'unselect-all') {
        this.selectedActivities = []
      }
    },
    handleSelectAll(checked) {
      if (checked) {
        // Select all non-reverted activities in the filtered list
        this.selectedActivities = this.filteredHistory.filter(activity => !activity.isReverted)
      } else {
        this.selectedActivities = []
      }
    },
    clearSelection() {
      this.selectedActivities = []
    },
    selectActivity(activity) {
      const activityIndex = this.selectedActivities.findIndex(kw => kw.id === activity.id)
      if (activityIndex !== -1) {
        this.selectedActivities.splice(activityIndex, 1)
        return
      }
      this.selectedActivities.push(activity)
    },
    openConfirmationModal(undoType) {
      this.modalConfig = {
        size: ConfirmUndoModalSize,
        modal: ConfirmUndoModal,
        undoType: undoType,
      }
    },
    async confirmUndoActivity(undoType) {
      this.$emit('set-page-loading', true, 'ACTIVITY_HISTORY')
      this.modalConfig = null

      switch (undoType) {
        case UNDO_TYPES.UNDO:
          await this.undoActivity(this.selectedActivities)
          break
        case UNDO_TYPES.UNDO_AND_REJECT:
          await this.undoAndRejectActivity(this.selectedActivities)
          break
        case UNDO_TYPES.UNDO_ALL:
          await this.undoAllActivities()
          break
      }

      this.clearSelection()
      this.$emit('refetch-activity-history')
    },
    async undoActivity(activities) {
      const ids = activities.map(activity => activity.id)
      this.$gtm.trackEvent({
        event: 'kw_suggestion_history_undo',
        suggestions: ids.length,
      })
      try {
        const payload = {
          user_email: this.context.user.email,
          ids: ids,
        }
        await this.$http.post(`/search/site/${this.context.site_id}/keyword-suggestions/undo`, payload)
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to undo activity. Please try again later.',
            type: 'error',
          },
        })
      }
    },
    async undoAllActivities() {
      try {
        await this.$http.post(
          `/search/site/${this.context.site_id}/campaign/${this.selectedCampaign.value}/keyword-suggestions/undo-all`,
          {
            user_name: this.context.user_name,
          },
        )
        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: 'Reverting all autopilot suggestions started. This might take some time.',
            type: 'success',
          },
        })
      } catch (error) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to undo all the activities. Please try again later.',
            type: 'error',
          },
        })
      }
    },
    async undoAndRejectActivity(activities) {
      console.log('trigger undo and reject', activities) // placeholder
    },
    handleAction(action, keyword) {
      if (action === 'Undo & Reject') {
        this.openConfirmationModal(UNDO_TYPES.UNDO_AND_REJECT)

        this.toggleRowSelection(keyword)
      }

      this.actionMenuOpen = null
    },
    closeModal() {
      this.modalConfig = null
      this.isMetricPanelOpen = false
    },
    toggleKwActionMenu(keyword) {
      this.actionMenuOpen = keyword
    },
    handleClick(undoType, keyword) {
      this.openConfirmationModal(undoType)
      this.isMetricPanelOpen = false
      this.toggleRowSelection(keyword)
    },
    expandKeyword(keywordObj) {
      if (this.expandedKeyword === keywordObj) {
        this.expandedKeyword = null
        this.isMetricPanelOpen = false
      } else {
        this.expandedKeyword = keywordObj
        this.isMetricPanelOpen = true
      }
    },
  },
}
</script>

<style scoped>
.main-container {
  overflow-y: auto;
  height: calc(100vh - 211px);
}

.metrics-panel {
  position: fixed;
  top: 100px;
  right: 0;
  bottom: 0;
  width: 400px;
  min-width: 300px;
  z-index: 20;
  border-left: 1px solid #e5e7eb;
  overflow-y: auto;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/* Panel slides in from the right */
.slide-enter-active,
.slide-leave-active {
  transition:
    transform 0.3s ease-out,
    opacity 0.3s ease-out;
}

.slide-enter-from {
  transform: translateX(100%);
  opacity: 0;
}

.slide-enter-to {
  transform: translateX(0);
  opacity: 1;
}

.slide-leave-from {
  transform: translateX(0);
  opacity: 1;
}

.slide-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
.max-h-52 {
  max-height: 13rem;
}
</style>
