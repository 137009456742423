<template>
  <div>
    <div class="m-4">
      <ic-cross
        class="absolute top-6 right-6"
        @close="close"
      />
      <div class="h4 border-b py-4">
        Edit Selection
        <span class="h4 text-bb-brand-purple">({{ keywordsToEdit.length }})</span>
      </div>
      <div class="w-full pt-4">
        <div class="space-y-4 overflow-y-auto text-container base-scrollbar">
          <div
            v-for="(suggestion, index) in keywordsToEdit"
            :key="index"
          >
            <div>
              <div>
                <p class="text-sm font-bold">"{{ suggestion.original_text }}"</p>
                <p class="text-sm font-normal">change to:</p>
              </div>
              <div class="px-0.5">
                <text-input
                  v-model="suggestion.text"
                  :input-name="suggestion.id"
                  :input-id="suggestion.id"
                />
              </div>
              <div
                v-if="suggestion.campaign_name !== 'All Campaigns'"
                class="flex gap-6 pt-6"
              >
                <base-radio-button
                  v-for="option in positiveOrNegative"
                  :key="`${option.value}-${index}`"
                  :option="option"
                  :option-label="() => option.value"
                  :option-id="() => `${option.value}-${index}`"
                  :option-value="() => option.value"
                  :checked="option.value == suggestion.suggestion_type"
                  @input="
                    val => {
                      selectSuggestionType(val, suggestion.id)
                    }
                  "
                >
                  <template #label>
                    <status :status="option.value" />
                  </template>
                </base-radio-button>
              </div>
              <div class="flex items-center justyify-between gap-x-2 pt-4">
                <div
                  v-for="type in matchTypes"
                  :key="type.value"
                  class="py-1 px-4 bg-neutral-0 rounded-md cursor-pointer border hover:border-bb-brand-purple duration-200 ease-linear"
                  :class="suggestion.match_type === type.value ? 'border-bb-brand-purple' : 'border-neutral-0'"
                  @click="selectMatchType(type.value, suggestion.id)"
                >
                  <p class="font-normal text-sm text-bb-text-default">{{ type.label }}</p>
                </div>
              </div>
            </div>
            <hr
              v-if="index + 1 < keywordsToEdit.length"
              class="bg-neutral-50 my-4"
            />
          </div>
        </div>
      </div>
      <div class="flex justify-center">
        <loader-ellipsis
          v-if="sendingRequest"
          class="text-primary mx-auto my-12"
        />
      </div>
      <div
        v-if="selectedKeywords.length !== 0 && !sendingRequest"
        class="pt-10 gap-4 flex flex-row items-center"
      >
        <MergeButtonRound
          brightbid
          button-type="secondary"
          class="w-full"
          @click="close"
        >
          <p>Cancel</p>
        </MergeButtonRound>
        <MergeButtonRound
          brightbid
          class="w-full"
          @click="submit('accept')"
        >
          <div class="flex gap-2 items-center">
            <ic-plus :size="16" />
            Add
          </div>
        </MergeButtonRound>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderEllipsis from '@/components/loader/EllipsisLoader.vue'
import TextInput from '@/components/input/brightbid/TextInput.vue'
import BaseRadioButton from '@/components/input/base/BaseRadioButton.vue'
import Status from '@/components/shared/Status.vue'

import IcCross from '@/components/icon/ic-cross.vue'
import IcPlus from 'vue-material-design-icons/Plus.vue'

export default {
  name: 'EditPanel',
  components: { BaseRadioButton, IcCross, LoaderEllipsis, TextInput, Status, IcPlus },
  props: {
    selectedKeywords: {
      type: Array,
      required: true,
    },
    sendingRequest: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      positiveOrNegative: [
        { label: 'Positive', value: 'positive' },
        { label: 'Negative', value: 'negative' },
      ],
      matchTypes: [
        { label: 'Phrase', value: 'phrase' },
        { label: 'Exact', value: 'exact' },
        { label: 'Broad', value: 'broad' },
      ],
      keywordsToEdit: [],
    }
  },
  watch: {
    selectedKeywords(newVal) {
      if (newVal.length === this.keywordsToEdit.length) return
      if (newVal.length > this.keywordsToEdit.length) {
        const suggestionIds = this.keywordsToEdit.map(suggestion => suggestion.id)
        const newSuggestion = newVal.find(suggestion => !suggestionIds.includes(suggestion.id))

        const suggestion = {
          original_text: newSuggestion.keyword_text ?? newSuggestion.searchTermView_searchTerm,
          text: newSuggestion.keyword_text ?? newSuggestion.searchTermView_searchTerm,
          id: newSuggestion.id,
          original_type: newSuggestion.suggestion_type,
          suggestion_type: newSuggestion.suggestion_type,
          match_type: newSuggestion.match_type.toLowerCase(),
          campaign_id: newSuggestion.campaign_id,
          campaign_name: newSuggestion.campaign_name,
        }

        this.keywordsToEdit.push(structuredClone(suggestion))
      } else {
        // REMOVE SUGGESTION
        const suggestionIds = newVal.map(suggestion => suggestion.id)
        const removedSuggestion = this.keywordsToEdit.find(suggestion => !suggestionIds.includes(suggestion.id))
        const indexOfRemovedSuggestion = this.keywordsToEdit.findIndex(
          suggestion => suggestion.id === removedSuggestion.id,
        )
        this.keywordsToEdit.splice(indexOfRemovedSuggestion, 1)
      }
    },
  },
  mounted() {
    this.keywordsToEdit = structuredClone(this.selectedKeywords).map(suggestion => {
      return {
        original_text: suggestion.keyword_text ?? suggestion.searchTermView_searchTerm,
        text: suggestion.keyword_text ?? suggestion.searchTermView_searchTerm,
        id: suggestion.id,
        original_type: suggestion.suggestion_type,
        suggestion_type: suggestion.suggestion_type,
        match_type: suggestion.match_type.toLowerCase(),
        campaign_id: suggestion.campaign_id,
        campaign_name: suggestion.campaign_name,
      }
    })
  },
  methods: {
    close() {
      this.$emit('close')
    },
    submit(action) {
      this.$emit('submit', this.keywordsToEdit, action)
    },
    selectSuggestionType(option, id) {
      const suggestion = this.keywordsToEdit.find(suggestion => suggestion.id === id)
      suggestion.suggestion_type = option
    },
    selectMatchType(type, id) {
      const suggestion = this.keywordsToEdit.find(suggestion => suggestion.id === id)
      suggestion.match_type = type
    },
  },
}
</script>

<style scoped lang="scss">
.text-container {
  height: calc(100vh - 370px);
}
</style>
